import { has, values } from 'lodash';

import { VENDORS } from 'constants/globals';
import { checkIsMailingConnected } from 'lib/apiV6';

export const isMailingAppConnected = (user: AH$User): boolean =>
  values(VENDORS.messaging).some((app) => has(user.socialAccounts, app.id));

export const getIsMailingConnectInvalid = async (): Promise<boolean> => {
  try {
    await checkIsMailingConnected();
  } catch (e) {
    if ((e as any).response.status === 403) {
      return true;
    }
  }
  return false;
};
