import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { get, includes, isEmpty, omit } from 'lodash';

import * as tracking from 'tracking';
import { LINK_FOR_EXT, LINK_FOR_EXT_MATCHY } from 'constants/globals';
import thematize from 'lib/thematize';
import { getLinkForHelpCenter, isAHCompany, isHiringManager } from 'lib/utils';
import { whiteLabelName } from 'lib/whitelabel';
import { useCustomRouterLocation, useSelector } from 'hooks';
import Icon from 'components/Base/Icon';
import useAtsFolders from 'components/Folders/hooks/useAtsFolders';
import Logo from 'components/Logo';
import styles from 'components/SideMenu/SideMenu.scss';
import BottomItem from './BottomItem';
import { dropSideLines } from './lib';
import messages from './messages';
import TopItem from './TopItem';
import User from './User';

const theme = thematize(styles);

const LogoComponent = ({
  isLink = true,
  link,
}: {
  isLink?: boolean;
  link?: string;
}): React.ReactElement => {
  const location = useCustomRouterLocation();
  const isAtHomeLocation = ['/menu/'].includes(location.pathname);

  return (
    <div className={theme('item')}>
      <Logo
        link={link}
        containerClassName={theme('logo', { active: isAtHomeLocation })}
        isLink={isLink}
      />
    </div>
  );
};

interface DropDownButtonProps {
  icon: React.ReactElement;
  title: React.ReactElement;
  url: string;
  opened?: boolean;
}

const DropDownButton = ({
  url,
  icon,
  title,
  opened = false,
}: DropDownButtonProps): React.ReactElement => (
  <Link
    to={url}
    onClick={() => {
      tracking.clickSideMenu(url);
    }}
  >
    <div className={theme('dropdown-button', { opened })}>
      <div className={theme('icon')}>{icon}</div>
      <div className={theme('dropdown-button-title')}>{title}</div>
    </div>
  </Link>
);

export const SideMenu = (): React.ReactElement => {
  const { pathname } = useCustomRouterLocation();
  const { companyFolderId } = useAtsFolders();
  const user = useSelector<AH$User>(({ app }) => app.user);
  const isAHCompanyId = isAHCompany(user); // TODO: Поменять на GMS === 4
  const { canSearch } = user;
  const isWorker =
    (user.role === 'worker' || user.role === 'service_admin') && !user.company.isExtCompany;
  const isHomeLink = canSearch || isWorker;
  const homeLink = isWorker && !canSearch ? '/menu/' : '/';
  const helpLink = getLinkForHelpCenter(whiteLabelName);
  const isHM = isHiringManager(user.role);
  const isSearchVisible = !user.company.isExtCompany && !isHM && canSearch;
  const isAdminWithoutSearch = includes(['admin_without_search'], user.role);
  const isFoldersVisible =
    (!includes(['super_user', 'sales_manager'], user.role) &&
      !user.company.isExtCompany &&
      canSearch) ||
    isAdminWithoutSearch ||
    isHM;
  const isSuperUserOrSalesManager = includes(['super_user', 'sales_manager'], user.role);
  const apiManagementIsVisible = user.apiManager && !user.company.isExtCompany;
  const ordersIsVisible = user.role === 'sales_manager';
  const companyIsVisible =
    includes(['admin', 'admin_without_search', 'service_admin'], user.role) &&
    !user.company.isExtCompany;
  const statisticsIsVisible = companyIsVisible || isWorker;
  const appsIsVisible =
    !isSuperUserOrSalesManager && !user.company.isExtCompany && !isHM && canSearch;
  const isDEVisible = user.dataEnrichmentEnabled && !isHM && canSearch;
  const isSearchDropdownButtonActive = ['/', '/intelligence/'].includes(pathname);
  const isFoldersDropdownButtonActive = pathname.includes('folders');
  const isMessagingVisible =
    (!!get(user, 'company.messaging') && !isHM && canSearch) || isAdminWithoutSearch;
  const isMessagingDropdownButtonActive =
    pathname.startsWith('/messaging') && !isFoldersDropdownButtonActive;
  const isTelegramMessengerButtonActive = pathname.includes('telegram-messenger');
  const isATSVisible = get(user.company, 'ats') && !isHM && canSearch;
  const isSequencesVisible = isMessagingVisible && user.company?.sequences;

  const items = {
    topItems: [
      {
        name: 'profiles',
        title: <FormattedMessage {...messages.search} />,
        visible: isSearchVisible,
        containerClassName: theme('item-dropdown', {
          active: isSearchDropdownButtonActive,
        }),
        isButtonActive: isSearchDropdownButtonActive,
        isUsingRouter: true,
        iconName: 'search',
        linkTo: isATSVisible || companyFolderId ? undefined : '/',
        ...(isATSVisible || companyFolderId
          ? {
              buttonContent: (
                <DropDownButton
                  url="/"
                  icon={<Icon type="search" />}
                  title={<FormattedMessage {...messages.search} />}
                />
              ),
              children: [
                {
                  title: <FormattedMessage {...messages.searchForm} />,
                  linkTo: '/',
                  isUsingRouter: true,
                },
                { isLine: true },
                isATSVisible
                  ? {
                      name: 'create',
                      title: <FormattedMessage {...messages.createProfile} />,
                      linkTo: '/profiles/create/',
                      isUsingRouter: true,
                    }
                  : {},
                companyFolderId
                  ? {
                      name: 'atsFolder',
                      title: <FormattedMessage {...messages.editedProfiles} />,
                      linkTo: `/?q=folder[0]:id-${companyFolderId}`,
                      isUsingRouter: !isSearchDropdownButtonActive,
                      onClick: () => tracking.startSearch(tracking.SearchType.editedProfiles),
                    }
                  : {},
              ].filter((item) => !isEmpty(item)),
            }
          : {}),
      },
      {
        name: 'folders',
        title: <FormattedMessage {...messages.vacancies} />,
        visible: isFoldersVisible,
        ...(companyIsVisible && !isAdminWithoutSearch
          ? {
              containerClassName: theme('item-dropdown', {
                active: isFoldersDropdownButtonActive,
              }),
              isButtonActive: isFoldersDropdownButtonActive,
              buttonContent: (
                <DropDownButton
                  url="/folders/"
                  icon={<Icon type="folder" />}
                  title={<FormattedMessage {...messages.vacancies} />}
                />
              ),
              children: [
                {
                  title: <FormattedMessage {...messages.vacancies} />,
                  linkTo: '/folders/',
                  isUsingRouter: true,
                },
                {
                  title: <FormattedMessage {...messages.statuses} />,
                  linkTo: '/folders/statuses/',
                  isUsingRouter: true,
                },
              ].filter((item) => !isEmpty(item)),
            }
          : {
              isUsingRouter: true,
              linkTo: isAdminWithoutSearch ? '/folders/statuses/' : '/folders/',
              iconName: 'folder',
            }),
      },
      {
        visible: isMessagingVisible,
        ...(isAdminWithoutSearch
          ? {
              name: 'messaging',
              title: <FormattedMessage {...messages.messaging} />,
              linkTo: '/messaging/settings',
              isUsingRouter: true,
              iconName: 'messengers',
            }
          : {
              name: 'messaging',
              title: <FormattedMessage {...messages.messaging} />,
              containerClassName: theme('item-dropdown', {
                active: isMessagingDropdownButtonActive,
              }),
              isButtonActive: isMessagingDropdownButtonActive,
              buttonContent: (
                <DropDownButton
                  url="/messaging/sent/"
                  icon={<Icon type="messengers" />}
                  title={<FormattedMessage {...messages.messaging} />}
                />
              ),
              children: [
                {
                  title: <FormattedMessage {...messages.sentMessages} />,
                  linkTo: '/messaging/sent/',
                  isUsingRouter: true,
                },
                isSequencesVisible
                  ? {
                      title: <FormattedMessage {...messages.sequences} />,
                      linkTo: '/messaging/sequences/',
                      isUsingRouter: true,
                    }
                  : {},
                {
                  title: <FormattedMessage {...messages.templates} />,
                  linkTo: '/messaging/templates/',
                  isUsingRouter: true,
                },
                get(user, 'company.messaging')
                  ? {
                      title: <FormattedMessage {...messages.messagingSettings} />,
                      linkTo: '/messaging/settings/',
                      isUsingRouter: true,
                    }
                  : {},
              ].filter((item) => !isEmpty(item)),
            }),
      },
      {
        name: 'data-enrichment',
        title: 'Data Enrichment',
        visible: isDEVisible,
        isUsingRouter: true,
        linkTo: '/data_enrichment/',
        iconName: 'dataEnrichment',
      },
      {
        visible: isAHCompanyId, // TODO: Потом поменять на GMS
        name: 'telegram-messenger',
        title: <FormattedMessage {...messages.telegramMessenger} />,
        containerClassName: theme('item-dropdown', {
          active: isTelegramMessengerButtonActive,
        }),
        isButtonActive: isTelegramMessengerButtonActive,
        buttonContent: (
          <DropDownButton
            url="/telegram-messenger/messages/"
            icon={<Icon type="telegram" />}
            title={<FormattedMessage {...messages.telegramMessenger} />}
          />
        ),
        children: [
          {
            title: <FormattedMessage {...messages.telegramMessengerMessages} />,
            linkTo: '/telegram-messenger/messages/',
            isUsingRouter: true,
          },
          {
            title: <FormattedMessage {...messages.telegramMessengerManager} />,
            linkTo: '/telegram-messenger/manager/',
            isUsingRouter: true,
          },
        ].filter((item) => !isEmpty(item)),
      },
      {
        name: 'orders',
        title: <FormattedMessage {...messages.serviceOrders} />,
        visible: ordersIsVisible,
        isUsingRouter: true,
        linkTo: '/service_orders/',
        iconName: 'serviceOrders',
      },
      {
        name: 'more',
        title: <FormattedMessage {...messages.more} />,
        visible: isSuperUserOrSalesManager,
        children: [
          {
            name: 'companies',
            title: <FormattedMessage {...messages.companies} />,
            linkTo: '/companies/',
            isUsingRouter: true,
          },
          {
            name: 'managers',
            title: <FormattedMessage {...messages.managers} />,
            linkTo: '/managers/',
            isUsingRouter: true,
          },
          {
            name: 'active-users',
            title: <FormattedMessage {...messages.activeUsers} />,
            linkTo: '/active-users/',
            isUsingRouter: true,
          },
          {
            name: 'banned-links',
            title: <FormattedMessage {...messages.bannedLinks} />,
            linkTo: '/profiles/ban/',
            isUsingRouter: true,
          },
          {
            name: 'metaqueries',
            title: <FormattedMessage {...messages.metaqueries} />,
            linkTo: '/metaqueries/',
            isUsingRouter: true,
          },
          {
            name: 'system-notifications',
            title: <FormattedMessage {...messages.systemNotifications} />,
            linkTo: '/notifications/system/',
            isUsingRouter: true,
          },
          {
            name: 'extension-notifications',
            title: <FormattedMessage {...messages.extensionNotifications} />,
            linkTo: '/notifications/',
            isUsingRouter: true,
          },
        ].filter((item) => !isEmpty(item)),
      },
    ] as const,
    bottomItems: [
      {
        name: 'extension',
        title: <FormattedMessage {...messages.extension} />,
        visible: true,
        linkTo: whiteLabelName === 'matchy' ? LINK_FOR_EXT_MATCHY : LINK_FOR_EXT,
        iconName: 'extension',
        target: '_blank',
      },
      {
        name: 'help',
        visible: true,
        linkTo: helpLink,
        iconName: 'question',
        target: '_blank',
        title: <FormattedMessage {...messages.help} />,
      },
      {
        name: 'account',
        visible: true,
        iconName: 'default',
        title: <FormattedMessage {...messages.account} />,
        children: (
          <User
            name={user.name}
            company={user.company.companyName}
            isExt={user.company.isExtCompany}
            links={dropSideLines(
              [
                companyIsVisible
                  ? {
                      name: 'company',
                      title: <FormattedMessage {...messages.company} />,
                      linkTo: '/company/',
                      isUsingRouter: true,
                    }
                  : {},
                statisticsIsVisible
                  ? {
                      name: 'statistics',
                      title: <FormattedMessage {...messages.statistics} />,
                      linkTo: `${isWorker ? '' : '/company'}/statistics/search/`,
                      isUsingRouter: true,
                    }
                  : {},
                apiManagementIsVisible
                  ? {
                      name: 'api-management',
                      title: <FormattedMessage {...messages.apiManagement} />,
                      linkTo: '/api_management/',
                      isUsingRouter: true,
                    }
                  : {},
                { isLine: true },
                appsIsVisible
                  ? {
                      name: 'apps',
                      title: <FormattedMessage {...messages.apps} />,
                      linkTo: '/apps/',
                      isUsingRouter: true,
                    }
                  : {},
                {
                  isUsingRouter: true,
                  name: 'settings',
                  title: <FormattedMessage {...messages.settings} />,
                  linkTo: '/settings/',
                },
                {
                  name: 'logout',
                  title: <FormattedMessage {...messages.logout} />,
                  linkTo: '/logout/',
                },
              ].filter((item) => !isEmpty(item))
            )}
          />
        ),
      },
    ] as const,
  };

  return (
    <div className={theme('container')}>
      <div className={theme('container-top')}>
        <LogoComponent isLink={isHomeLink} link={homeLink} />
        {items.topItems
          .filter(({ visible }) => visible)
          .map((item) => (
            <TopItem
              key={`top-item-${item.name}`}
              {...omit(item, 'visible')}
              alignItems={item.name === 'messaging' && !isAdminWithoutSearch ? 'left' : undefined}
            />
          ))}
      </div>

      <div className={theme('container-bottom')}>
        {items.bottomItems
          .filter(({ visible }) => visible)
          .map((item) => (
            <BottomItem key={`bottom-item-${item.name}`} {...omit(item, 'visible')} />
          ))}
      </div>
    </div>
  );
};

export default SideMenu;
