import { MutableRefObject, Ref } from 'react';
import { Dispatch } from '@reduxjs/toolkit';
import {
  camelCase,
  capitalize,
  compact,
  curry,
  escape,
  flatten,
  flow,
  flowRight,
  get,
  includes,
  isArray,
  isEmpty,
  keys,
  mapValues,
  maxBy,
  merge,
  mergeWith,
  noop,
  omit,
  omitBy,
  uniqBy,
} from 'lodash';
import moment from 'moment';
import smoothscroll from 'smoothscroll';
import { parse as parseUrl } from 'url';

import {
  ALL_DISPLAY_SECTIONS,
  APP_NODE,
  EXTENSION_ID,
  LINK_FOR_HELP_CENTER,
  LINK_FOR_HELP_CENTER_MATCHY,
  USED_CONTACT_TYPES,
} from 'constants/globals';
import { camelKeys, camelKeysRecursive } from 'lib/object';
import { whiteLabelName } from 'lib/whitelabel';
import { SingularProfileFieldKey } from 'components/Merging/constants';
import { capitalizeEvery } from './string';
import { addGetParams, parseURL } from './url';

export const SOURCE_NAME = {
  'linkedin.com': 'Linkedin',
  'career.habr.com': 'Career Habr',
  'habr.com': 'Habr',
  'github.com': 'Github',
  'hh.ru': 'HH',
  'vk.com': 'VK',
  'facebook.com': 'Facebook',
  'stackoverflow.com': 'Stackoverflow',
  'stackexchange.com': 'Stackexchange',
  'askubuntu.com': 'Askubuntu',
  'superuser.com': 'Superuser',
  'serverfault.com': 'Serverfault',
  'kaggle.com': 'Kaggle',
  'twitter.com': 'Twitter',
  'behance.net': 'Behance',
  'about.me': 'About',
  'klout.com': 'Klout',
  'plus.google.com': 'Google Plus',
  'xing.com': 'Xing',
  'gitlab.com': 'Gitlab',
  'bitbucket.org': 'Bitbucket',
  'dev.to': 'Dev To',
  'hackerrank.com': 'Hackerrank',
  'getmatch.ru': 'Getmatch',
  't.me': 'Telegram',
  'telegram.me': 'Telegram',
  'livejournal.com': 'Livejournal',
};

/**
 * Ids generator function
 * @returns {Iterable.<number>}
 */
function* idGenerator(): Generator<number> {
  let i = 0;

  /* eslint-disable */
  // noinspection InfiniteLoopJS
  while (true) {
    yield i++;
  }
  /* eslint-enable */
}

/**
 * Ids generator
 * @type {Iterable.<number>}
 */
const gen = idGenerator();

/**
 * Generates unique ID
 * @returns {number} Unique natural number
 */
export function generateId(): number {
  return gen.next().value;
}

/**
 * Returns last CSRF tokens from cookies string
 * @TODO Remove after MAG-5085 fix
 * @param {string} cookies
 * @returns {Array}
 */
export function getCSRFToken(): string | undefined {
  return window.CSRF_TOKEN;
}

/**
 * Refuses fn call if role is in a roles list
 * @param {Array.<string>} roles
 * @param {string} role
 * @returns {Function}
 */
export function restrict<F extends (...args: unknown[]) => any>(
  roles: Array<string>,
  role: string
): (fn: F) => F | typeof noop {
  if (includes(roles, role)) {
    return () => noop;
  }

  return (fn) => fn;
}

export function clientsOnly<F extends (...args: unknown[]) => any>(userRole: string) {
  return restrict<F>(['super_user', 'sales_manager'], userRole);
}

export const getIconHostName = (url: string | null): string => {
  const ICON_EXCEPTIONS = [
    'ru.stackoverflow',
    'careers.stackoverflow',
    'career.habr',
    'sqa.stackexchange',
    'programmers.stackexchange',
    'unix.stackexchange',
    'math.stackexchange',
    'physics.stackexchange',
    'codereview.stackexchange',
    'softwarerecs.stackexchange',
    'crypto.stackexchange',
    'gamedev.stackexchange',
    'stats.stackexchange',
    'apple.stackexchange',
    'softwareengineering.stackexchange',
    'ux.stackexchange',
    'graphicdesign.stackexchange',
    'drupal.stackexchange',
    'salesforce.stackexchange',
    'webmasters.stackexchange',
    'cs.stackexchange',
    'cstheory.stackexchange',
    'android.stackexchange',
    'dba.stackexchange',
    'picasaweb.google',
    'play.google',
    'plus.google',
    'sites.google',
    'profiles.google',
    'addons.mozilla',
    'lleo.aha',
    'goldenline.pl',
    'huggingface.co',
  ];
  const parsedURL = parseURL(url || '');
  const arr = (parsedURL.hostname || '').replace(/^www\./, '').split('.');
  const beginning = arr.slice(0, arr.length - 1).join('.');
  const ending = arr[arr.length - 1];
  const index = ICON_EXCEPTIONS.indexOf(beginning);

  if (url === 'Resume') return 'resume';
  if (index > -1) return `${ICON_EXCEPTIONS[index]}.${ending}`;
  return parsedURL.primaryDomain;
};

export const getIconKeyword = (hostname?: string): AH$AccountKind => {
  if (hostname === 'resume') return hostname;
  if (hostname) {
    const keyword = hostname.toLowerCase().split('.');

    keyword.pop();
    return keyword.join('-') as AH$AccountKind;
  }
  return '' as AH$AccountKind;
};

/**
 * Returns site hostname
 * @TODO remove AH dependency
 * @param {string} url
 * @returns {string}
 */
export function accountHostName(url: string | null): string {
  return getIconHostName(url);
}

/**
 * Creates icon name from hostname
 * @TODO remove AH dependency
 * @param {string} hostname
 * @returns {string}
 */
export function hostNameIcon(hostname: string): AH$AccountKind {
  return getIconKeyword(hostname);
}

/**
 * Returns icon name from url
 * @type {Function}
 * @param {string}
 * @returns {string}
 */
export const accountIconName: (url: string) => AH$AccountKind = flowRight(
  hostNameIcon,
  accountHostName
);

/**
 * Filters rating
 * @param {Array.<Object>} ratings
 * @returns {Array.<Object>}
 */
function filterRating(ratings: Array<Record<string, any>>) {
  const resourcesWhitelist = [
    'github_commit',
    'github_repo',
    'stackoverflow_tags',
    'ru.stackoverflow_tags',
    'google_play',
    'kaggle',
  ];

  return ratings.filter((rating) => includes(resourcesWhitelist, rating.resource));
}

/**
 * Removes unsupported skills resources
 * @param {Array.<Object>} skills
 * @returns {Array.<Object>}
 */
export function filterSkillResources(skills: Array<API$ProfileSkill>): Array<API$ProfileSkill> {
  return skills.map((skill) =>
    // eslint-disable-next-line prefer-object-spread
    Object.assign({}, skill, {
      additionalSkills: filterSkillResources(skill.additionalSkills),
      ratingOnResources: skill.ratingOnResources ? filterRating(skill.ratingOnResources) : null,
    })
  );
}

/**
 * Returns skill in pretty form
 * @param {string} skill
 * @returns {string}
 */
export function formatSkillName(skill = ''): string | undefined {
  const nortmalizedSkill = skill.toLowerCase();
  const skillsMap = new Map([
    ['javascript', 'JavaScript'],
    ['php', 'PHP'],
    ['css', 'CSS'],
    ['html', 'HTML'],
    ['devops', 'DevOps'],
    ['.net', '.NET'],
    ['qa', 'QA'],
    ['objective-c', 'Objective-C'],
    ['iphone', 'iPhone'],
    ['ios', 'iOS'],
    ['sql', 'SQL'],
    ['jquery', 'jQuery'],
    ['grep', 'grep'],
    ['sh', 'sh'],
    ['bash', 'bash'],
    ['shell', 'shell'],
    ['dns', 'DNS'],
  ]);

  return skillsMap.has(nortmalizedSkill)
    ? skillsMap.get(nortmalizedSkill)
    : capitalizeEvery(escape(skill));
}

/**
 * Determines user rating level
 * @param {number} [rating]
 * @returns {string}
 */
export function ratingStatus(rating = 0): 'gold' | 'silver' | 'bronze' | '' {
  if (rating >= 0.9) {
    return 'gold';
  }
  if (rating >= 0.7) {
    return 'silver';
  }
  if (rating >= 0.5) {
    return 'bronze';
  }

  return '';
}

/**
 * Finds top level
 * @param {number} rating Normalized rating (0 < rating < 1)
 * @returns {number|string} Top %%
 */
function getTopPercents(rating: number): number | string {
  const percent = (1 - rating) * 100;

  if (percent > 0.5) {
    return Math.round(percent);
  }
  if (percent > 0) {
    return percent.toPrecision(1);
  }

  return 0.01;
}

/**
 * Find best achievement
 * @param {string} skill
 * @param {Array.<Object>} ratingOnResources
 * @returns {{status: string, resource: string, rating: number}}
 */
export function bestAchievement({
  skill,
  ratingOnResources,
}: {
  ratingOnResources: Array<AH$SkillRating> | null;
  skill: string;
}): AH$ProfileAchievement {
  if (isEmpty(ratingOnResources)) {
    return { skill, status: '', resource: '', rating: 0 };
  }

  const { normRating, resource } = maxBy(ratingOnResources, 'normRating') as AH$SkillRating;

  return {
    resource,
    skill,
    status: ratingStatus(normRating),
    rating: getTopPercents(normRating),
  };
}

export const filterDeletedValues = <T extends { deleted?: boolean }>(data: Array<T>): Array<T> =>
  data.filter(({ deleted }) => !deleted);

export const filterDeletedSkills = (skills: AH$ProfileSkills): AH$ProfileSkills => {
  const allSkills = skills.allSkills
    .map((group) => ({
      ...group,
      skills: filterDeletedValues(group.skills),
    }))
    .filter(({ skills }) => skills.length);
  const principalSkills = skills.principalSkills
    .map((group) => ({
      ...group,
      skills: filterDeletedValues(group.skills),
    }))
    .filter(({ skills }) => skills.length);
  const shortProfileSkills = filterDeletedValues(skills.shortProfileSkills);

  return {
    allSkills,
    principalSkills,
    shortProfileSkills,
  };
};
/*
 * Функция удаляет контакты, у которых флаг deleted = true
 * @deprecated
 *  */
export const filterDeletedContacts = ({
  contactsById: oldContactsById,
  contactsIdByType: oldContactsIdByType,
}: {
  contactsById: AH$ProfileContactsById;
  contactsIdByType: Map<string, Array<number>>;
}): { contactsById: AH$ProfileContactsById; contactsIdByType: Map<string, Array<number>> } => {
  const contactsById: AH$ProfileContactsById = {};
  const contactsIdByTypeArray = Array.from(oldContactsIdByType).map<[string, Array<number>]>(
    ([type, ids]) => [
      type,
      ids.filter((id) => {
        const contact = oldContactsById[id];
        const isNotDeleted = contact && !contact.deleted;

        if (isNotDeleted) {
          contactsById[id] = contact;
        }
        return isNotDeleted;
      }),
    ]
  );

  return {
    contactsById,
    contactsIdByType: new Map(contactsIdByTypeArray),
  };
};

export function locationFull({
  city,
  regions,
  country,
}: {
  city: string;
  country: string;
  regions: Array<string>;
}): string {
  return compact([city, ...regions, country]).join(', ');
}

export function locationShort({ city, mainRegion, regions, country }: AH$ProfileLocation): string {
  const mainCity = city && mainRegion ? `${city}, ${mainRegion}` : city;

  return mainCity || regions.join(', ') || country;
}

export function filterResumes(resumes: Array<AH$ProfileResume>): Array<AH$ProfileResume> {
  const allowedResumesTypes = [
    'LINKEDIN_RESUME',
    'HH_RESUME',
    'E_STAFF',
    'MIRACLES_HH_FULL',
    'MIRACLES_HH_MINI',
    'PROFILE_FROM_FILE',
    'PROFILE_FROM_LLM_FILE_PARSER',
    'ATTACHED_FILE',
    'ENRICH_FROM_FILE',
    'GETMATCH_RESUME',
    'HH_RESUME_JSON',
    'CAREER_HABR_RESUME',
  ];

  return resumes
    .filter(({ type }) => includes(allowedResumesTypes, type))
    .sort((prev, curr) => curr.date - prev.date);
}

export function filterResumesAlt(
  resumes: Array<AH$ProfileResumeFull>
): Array<AH$ProfileResumeFull> {
  const allowedResumesTypes = [
    'LINKEDIN_RESUME',
    'HH_RESUME',
    'E_STAFF',
    'MIRACLES_HH_FULL',
    'MIRACLES_HH_MINI',
    'PROFILE_FROM_FILE',
    'PROFILE_FROM_LLM_FILE_PARSER',
    'ATTACHED_FILE',
    'ENRICH_FROM_FILE',
    'GETMATCH_RESUME',
    'HH_RESUME_JSON',
    'CAREER_HABR_RESUME',
  ];

  return resumes
    .filter(({ rawData }) => includes(allowedResumesTypes, rawData.resumeType))
    .sort((prev, curr) => curr.rawData.date - prev.rawData.date);
}

/**
 * Makes from array of objects object of arrays united by key
 *
 * @example
 * uniteByKeys(['name', 'age'], [{ name: 'John', age: 42 }, { name: 'Joseph', age: 33 }])
 * // => { name: ['John', 'Joseph'], age: [42, 33] }
 *
 * @param {Array.<string>} keys
 * @param {Array.<Object>} collection
 * @returns {Object.<string, Array>}
 */
export function uniteByKeys(
  keys: Array<SingularProfileFieldKey>,
  collection: Record<string, any>
): { [key: string]: Array<Record<string, any>> } {
  const data: Record<string, any> = {};

  keys.forEach((key) => {
    data[key] = [];
  });
  collection.forEach((profile: { [key: string]: any }) => {
    keys.forEach((key) => {
      data[key].push(profile[key]);
    });
  });

  return data;
}

export function scrollTo(position = 0): void {
  smoothscroll(position, 500);
}

/**
 * Returns page number, default = 1
 * @param {string|number} [page]
 * @returns {number}
 */
export function parsePageNumber(page?: any): number {
  return parseInt(page, 10) || 1;
}

function buildStatRedirectUrl(url: string, profileId: number): string {
  let builtUrl = url;

  if (profileId) {
    const params = {
      url,
      itag: new Date().toISOString(),
    };

    builtUrl = addGetParams(
      `${window.location.origin}/api/internal/profiles/${profileId}/redirect_to_url/`,
      params
    );
  }

  return builtUrl;
}

function buildAhRedirectUrl(
  extResp: { isInstalled: boolean },
  url: string,
  profileId: number
): string {
  const { hostname } = parseUrl(url);
  const isLinkedin = includes(hostname, 'linkedin');

  let builtUrl = url;

  if (extResp && extResp.isInstalled && isLinkedin) {
    builtUrl = `https://www.linkedin.com/?ah-redirect=${encodeURIComponent(url)}`;
  }

  return buildStatRedirectUrl(builtUrl, profileId);
}

export function tryOpenLinkWithReferer(url: string, profileId: number, userSetting: boolean): void {
  const win = window.open('', '_blank');
  const { chrome } = window;

  if (userSetting && chrome !== undefined && chrome.runtime !== undefined) {
    chrome.runtime.sendMessage(
      EXTENSION_ID,
      { type: 'IS_INSTALLED' },
      (resp: { isInstalled: boolean }) => {
        // @ts-ignore
        win.location = buildAhRedirectUrl(resp, url, profileId);
        get(win, 'focus', noop)();
      }
    );
  } else {
    // @ts-ignore
    win.location = buildStatRedirectUrl(url, profileId);
    get(win, 'focus', noop)();
  }
}

/**
 * Dispatches Promise action
 * @function
 * @param {Function} dispatch
 * @param {string} type
 * @param {Function} promiseFn
 */
export const promiseActionDispatchHelper = curry(
  (dispatch: Dispatch, type: string, promiseFn: () => Promise<any>) => {
    const onResolve = ({ payload }: any) => ({ type, payload });
    const onReject = (err: any) => ({
      type,
      payload: err,
      error: true,
    });

    return promiseFn().then(onResolve, onReject).then(dispatch);
  }
);

export const getSiteName = (hostname: string): string =>
  ['vk', 'hh'].includes(hostname) ? hostname : capitalize(hostname);

/**
 * Просто перенёс это из старого Кофескрипта, надо выкинуть и написать что-то красивое и понятное,
 * но у меня пока нет на это времени и сил
 * @param {string} href
 * @param {Object} [params]
 * @returns {string}
 */
export function getSourceName(href: string, params: Record<string, any> = {}): string {
  const parsedURL = parseURL(href);
  const { keyword, primaryDomain, hostname } = parsedURL;
  const source = params.fullHostname ? hostname : keyword;

  if (hostname === 'git') {
    return 'Git';
  }

  if (hostname === 'play.google.com') {
    return 'Google Play';
  }

  if (hostname === 'plus.google.com') {
    return 'Google+';
  }

  if (hostname === 'itunes.apple.com') {
    return 'iTunes';
  }

  if (hostname === 'about.me') {
    return 'About.me';
  }

  let sourceName = getSiteName(source as string);

  if (params.returnDomain) {
    sourceName += `.${primaryDomain.split('.')[1]}`;
  }

  return sourceName;
}

const uniqify = <T>(list: Array<T>): Array<T & { _id: number }> =>
  list.map((item) => ({ _id: generateId(), ...item }));

export const transformFolder = (folder: API$Candidate): AH$ProfileFolder => ({
  ...(camelKeys(folder.folder) as AH$Folder),
  recipientsCount: folder.recipients_count,
  candidateId: folder.id,
  profileId: folder.profile as number,
  candidateStatus: folder.status,
  modifiedAt: folder.updated_at,
  comments: (folder.comments || []).map(camelKeysRecursive) as Array<AH$ProfileComment>,
  creator: {
    ...folder.folder.creator,
    name: [folder.folder.creator.first_name, folder.folder.creator.last_name].join(' '),
  },
});

export const transformFolders = (folders: Array<API$Candidate>): Array<AH$ProfileFolder> =>
  folders
    .map(transformFolder)
    .sort((prev, curr) => Date.parse(curr.modifiedAt) - Date.parse(prev.modifiedAt));

export const transformTags = <T extends { displayTags: API$Profile['displayTags'] }>({
  displayTags: tags = [],
  ...profile
}: Readonly<T>): Omit<T, 'displayTags'> & { tags: AH$Profile['tags'] } => ({
  ...profile,
  tags,
});

export const flattenSkills = (skills: Array<AH$ProfileSkill>): Array<AH$ProfileSkill> =>
  skills.reduce(
    (acc, skill) => [...acc, skill, ...flattenSkills(skill.additionalSkills)],
    [] as Array<AH$ProfileSkill>
  );

export const transformSkills = <
  T extends {
    displaySkillGroups: API$Profile['displaySkillGroups'];
  }
>({
  displaySkillGroups = [],
  ...profile
}: Readonly<T>): typeof profile & {
  displaySkillGroups: AH$Profile['displaySkillGroups'];
  skills: AH$Profile['skills'];
} => {
  const allSkills = displaySkillGroups.map((group) => ({
    ...group,
    skills: filterSkillResources(group.skills),
  }));
  const principalSkills = allSkills
    .map((group) => ({
      ...group,
      skills: group.skills.filter((skill) => skill.isPrincipal),
    }))
    .filter(({ skills }) => !isEmpty(skills));
  const shortProfileSkills = uniqBy(
    flattenSkills(flatten(allSkills.map((group) => group.skills))),
    'skill'
  );

  const skills = { allSkills, principalSkills, shortProfileSkills };

  return { displaySkillGroups, skills, ...profile };
};

export const transformAchievements = <T extends { skills: AH$Profile['skills'] }>({
  skills,
  ...profile
}: Readonly<T>): typeof profile & {
  achievements: Array<AH$ProfileAchievement>;
  skills: AH$Profile['skills'];
} => {
  const achievements = skills.shortProfileSkills
    .filter(({ showAchievements }) => showAchievements)
    .sort((a, b) => b.normRating - a.normRating)
    .reduce<Array<AH$ProfileAchievement>>((acc, skill) => {
      const achievement = bestAchievement(skill);

      if (achievement.status) {
        return [...acc, achievement];
      }

      return acc;
    }, []);

  return { skills, achievements, ...profile };
};

export const transformTelegram = (
  contacts: Record<string, Array<API$ProfileContact | API$ProfileDisplayLink>>
) => ({
  ...contacts,
  telegram: contacts.telegram.map((contact) =>
    (contact as API$ProfileContact).value.startsWith('+') ? { ...contact, isPhone: true } : contact
  ),
});

export const transformContacts = (
  rawContacts: Record<string, Array<API$ProfileContact | API$ProfileDisplayLink>>,
  profileId: number,
  orderId: number
): { contactsById: AH$ProfileContactsById; contactsIdByType: Map<string, Array<number>> } => {
  const contacts = rawContacts.telegram ? transformTelegram(rawContacts) : rawContacts;
  const contactsIdByType: Map<string, Array<number>> = new Map();
  const sourceEditebleTypes = new Set(['fragment-contacts', 'fragment-userlinks']);

  if (Number.isInteger(orderId)) {
    sourceEditebleTypes.add('ah-help');
  }

  return USED_CONTACT_TYPES.reduce(
    (res, type) => {
      const contactsId: Array<number> = [];
      const contactsByType = get(contacts, type, []) as (
        | API$ProfileContact
        | API$ProfileDisplayLink
      )[];

      const contactsById = contactsByType.reduce((res, contact) => {
        const _id = generateId();
        const sourceInfo = contact.sourceInfo || {};
        const editable = sourceEditebleTypes.has((sourceInfo as AH$SourceInfo).type);

        contactsId.push(_id);
        return {
          ...res,
          [_id]: {
            ...contact,
            _id,
            type,
            profileId,
            editable,
            valid: true,
            saved: true,
          },
        };
      }, {});

      contactsIdByType.set(type, contactsId);
      return {
        contactsById: { ...res.contactsById, ...contactsById },
        contactsIdByType,
      };
    },
    { contactsById: {}, contactsIdByType: new Map() }
  );
};

export const transformProfileContacts = <
  T extends {
    displayContactMasks: API$ProfileDisplayContacts;
    displayContacts: API$ProfileDisplayContacts;
    displayLinks: Array<API$ProfileDisplayLink>;
    docId: number;
    orderId: number;
  }
>({
  displayContacts,
  displayContactMasks,
  displayLinks,
  docId,
  orderId,
  ...profile
}: Readonly<T>): typeof profile & {
  contactsById: AH$ProfileContactsById;
  contactsIdByType: Map<string, Array<number>>;
  displayContactMasks: AH$ProfileDisplayContacts;
  displayContacts: AH$ProfileDisplayContacts;
  displayLinks: Array<AH$ProfileDisplayLink>;
  docId: number;
  id: number;
  orderId?: number;
} => {
  const publicContacts = mapValues(displayContactMasks, (type) =>
    type.map((contact) => ({ ...contact, public: true }))
  );
  const allContacts = mergeWith(
    { ...displayContacts },
    { ...publicContacts },
    (objValue, srcValue) => {
      if (isArray(objValue)) {
        return objValue.concat(srcValue);
      }
      return srcValue;
    }
  );

  const transformedDisplayLinks: Array<AH$ProfileDisplayLink> = displayLinks.map((link) => ({
    ...link,
    domain: link.domain || parseUrl(link.url).hostname,
  }));
  const contacts = transformContacts(
    { ...allContacts, link: [...transformedDisplayLinks] },
    docId,
    orderId
  );

  return {
    ...profile,
    displayLinks: transformedDisplayLinks,
    displayContacts,
    displayContactMasks,
    ...contacts,
    docId,
    id: docId,
    orderId,
  };
};

export const transformPositions = <
  T extends {
    currentPositions: Array<API$ProfilePosition>;
    previousPositions: Array<API$ProfilePosition>;
  }
>({
  previousPositions = [],
  currentPositions = [],
  ...profile
}: Readonly<T>): typeof profile & {
  currentPositions: Array<AH$ProfilePosition>;
  previousPositions: Array<AH$ProfilePosition>;
} => ({
  previousPositions: uniqify<AH$ProfilePosition>(previousPositions),
  currentPositions: uniqify<AH$ProfilePosition>(currentPositions),
  ...profile,
});

export const transformOrders = <
  T extends {
    service_orders: Array<API$ProfileServiceOrder>;
  }
>({
  service_orders = [],
  ...profile
}: Readonly<T>): Omit<T, 'service_orders'> & {
  serviceOrders: AH$ProfileServiceOrder | Record<string, unknown>;
} => ({
  serviceOrders: {
    ordersById: (service_orders || []).reduce<AH$ProfileServiceOrder | Record<string, unknown>>(
      (res, order) => ({
        ...res,
        [order.id]: camelKeys({ ...order, data: camelKeys(order.data) }),
      }),
      {}
    ),
    ordersIds: (service_orders || []).map(({ id }) => id),
  },
  ...profile,
});

export const transformPhoto = <
  T extends {
    pictures: Array<string>;
  }
>({
  pictures,
  ...profile
}: Readonly<T>): typeof profile & { photo: string; pictures: Array<string> } => ({
  photo: pictures[0],
  pictures,
  ...profile,
});

export const transformAgeExact = <
  T extends {
    birthdateExact: boolean;
  }
>({
  birthdateExact,
  ...profile
}: Readonly<T>): typeof profile & { ageExact: boolean; birthdateExact: boolean } => ({
  ageExact: birthdateExact,
  birthdateExact,
  ...profile,
});

export const transformSchools = <
  T extends {
    educations: Array<API$ProfileSchool>;
  }
>({
  educations = [],
  ...profile
}: Readonly<T>): typeof profile & {
  educations: Array<AH$ProfileSchool>;
  schools: Array<AH$ProfileSchool>;
} => ({
  schools: uniqify(educations.map(camelKeys)) as Array<AH$ProfileSchool>,
  educations,
  ...profile,
});

export const transformCertificates = <
  T extends {
    coursesAndCertificates: Array<API$ProfileCertificate>;
  }
>({
  coursesAndCertificates,
  ...profile
}: T): typeof profile & {
  certificates: Array<AH$ProfileCertificate>;
  coursesAndCertificates: Array<AH$ProfileCertificate>;
} => {
  const defaultCoursesAndCertificates = coursesAndCertificates || [];

  return {
    certificates: uniqify(defaultCoursesAndCertificates),
    coursesAndCertificates: defaultCoursesAndCertificates,
    ...profile,
  };
};

export const transformFiles = <
  T extends {
    resumeTypesDates: Array<API$ProfileResume>;
  }
>({
  resumeTypesDates = [],
  ...profile
}: Readonly<T>): typeof profile & {
  attachments: Array<AH$ProfileAttachment>;
  resumeTypesDates: Array<AH$ProfileResume>;
} => {
  const transformedResumes: Array<AH$ProfileAttachment> = filterResumes(resumeTypesDates).map(
    ({ type, date, resumeId, userName, filename, url, enrichable, fileId }) => ({
      type,
      date,
      userName,
      id: resumeId,
      source: url,
      filename,
      data: '',
      enrichable,
      externalId: fileId,
    })
  );
  const attachments = transformedResumes.sort((prev, curr) => curr.date - prev.date);

  return {
    resumeTypesDates,
    attachments,
    ...profile,
  };
};

export const addProfileEvents = (apiProfiles: {
  profiles: API$ProfilesPayload['profiles'];
  profiles_events: API$ProfilesPayload['profiles_events'];
}): API$Profile[] => {
  const { profiles, profiles_events } = apiProfiles;

  return profiles.map((profile) => {
    const profileEvents = profiles_events?.[profile.docId] || [];

    return {
      ...profile,
      profileEvents,
    };
  });
};

export function transformProfile(profile: API$Profile): AH$Profile {
  const transform = flow(
    transformTags,
    transformSkills,
    transformAchievements,
    transformProfileContacts,
    transformPositions,
    transformOrders,
    transformPhoto,
    transformAgeExact,
    transformSchools,
    transformCertificates,
    transformFiles
  );

  return transform(profile);
}

export function cancelEventDefault<T extends React.SyntheticEvent>(event: T): void {
  event.preventDefault();
  event.stopPropagation();
}

export function setLocalStorage<T>(name: string, data: T): void {
  localStorage.setItem(name, JSON.stringify(data));
}

export function removeLocalStorage(name: string): void {
  localStorage.removeItem(name);
}

export function getLocalStorage(name: string): any {
  return JSON.parse(localStorage.getItem(name) || 'null');
}

export function setLocalStorageWithExpiry(key: string, value: any, days: number): void {
  const now = new Date();

  const item = {
    value,
    expiry: now.getTime() + days * 24 * 60 * 60 * 1000,
  };

  localStorage.setItem(key, JSON.stringify(item));
}

export function getLocalStorageWithExpiry(key: string): any {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

export const combineOrdersWithChosenProfile = (
  payload: AH$ProfileServiceOrder,
  orders: Map<number, AH$ProfileServiceOrder>
): Map<number, AH$ProfileServiceOrder> => {
  const order = merge(payload, {
    data: {
      profile: {
        orderId: payload.id,
        displaySkills: [],
        service_orders: [],
        location: [],
      },
    },
  });

  return new Map(orders.set(order.id, order));
};

export const getResourceName = (resource = ''): string => resource.split('_')[0];

export const isOpenerCrossOrigin = (): boolean => {
  try {
    return !window.opener.location.href;
  } catch (err) {
    return true;
  }
};

export function transformServiceOrder(
  serviceOrder: AH$ProfileServiceOrder
): AH$ProfileServiceOrder {
  return camelKeys({
    ...serviceOrder,
    data: camelKeys(serviceOrder.data),
  });
}

export const transformDisplaySections = (
  displaySections?: Array<string>
): AH$User['displaySections'] =>
  displaySections
    ? displaySections.reduce(
        (obj, section) => ({
          ...obj,
          [camelCase(section)]: true,
        }),
        {} as AH$User['displaySections']
      )
    : ALL_DISPLAY_SECTIONS;

export const transformUser = (
  user: API$User,
  systemNotifications?: AH$SystemNotification[]
): AH$User => ({
  id: user.id,
  activateDate: user.activate_date,
  name: `${user.first_name} ${user.last_name}`.trim(),
  firstName: user.first_name,
  lastName: user.last_name,
  email: user.email,
  role: user.role,
  canSearch: user.can_search,
  apiManager: user.api_manager,
  settings: camelKeys(user.settings),
  company: {
    ...camelKeys(user.company),
    creditStatus: user.company.credit_status
      ? {
          ...camelKeys(user.company.credit_status),
          giftPackDetail: camelKeys(user.company.credit_status.gift_pack_detail),
        }
      : {},
  },
  city: 'city' in user.location ? user.location.city : '',
  country: 'country' in user.location ? user.location.country : '',
  locationId: 'location_id' in user.location ? user.location.location_id : '',
  socialAccounts: camelKeysRecursive(omit(user.social_accounts, 'telegram')) || {},
  emailAliases: {
    aliases: [],
    from: {},
  },
  tosAcceptanceDate: user.tos_acceptance_date,
  searchFormType: user.search_form_type,
  metaQueriesEnabled: user.metaqueries_enabled || user.company.metaqueries_enabled || false,
  dataEnrichmentEnabled: Boolean(get(user, ['company', 'data_enrichment'], false)),
  displaySections: transformDisplaySections(user.display_sections),
  messagingSignature: user.messaging_signature,
  messagingUseSignature: user.messaging_use_signature,
  messagingViewsTracking: user.messaging_views_tracking,
  dateJoined: user.date_joined,
  abTests: user.ab_tests,
  licenceId: user.licence_id,
  systemNotifications: systemNotifications || [],
});

export const enrichApps = (
  socialAccounts: AH$SocialAccounts,
  availableApps: Array<string>,
  vendors: Partial<AH$RawApps | AH$RawApps['messaging']>
): AH$Apps =>
  keys(vendors).reduce((acc, key) => {
    const filteredSocialAccounts = omitBy(
      socialAccounts,
      (acc) => (acc.vendor === 'lever' && !acc.externalUserId) || acc.vendor === 'telegram'
    );
    const socialAccountKeys = Object.keys(filteredSocialAccounts);
    const value = get(vendors, key);
    const id: keyof AH$RawApps | keyof AH$RawApps['messaging'] = get(value, 'id');
    const isAvailable = includes(availableApps, id);
    const connected = includes(socialAccountKeys, camelCase(id));
    const semiConnected = !connected && id === 'lever' && !!socialAccounts.lever;
    const isReconnected = socialAccounts[id]?.active;

    if (whiteLabelName === 'matchy') {
      switch (key) {
        case 'huntflow_import':
        case 'messaging':
        case 'gmail': {
          return {
            ...acc,
            [key]: id
              ? {
                  ...value,
                  loading: false,
                  connected,
                  isAvailable,
                  semiConnected,
                  isReconnected,
                }
              : enrichApps(socialAccounts, availableApps, value),
          };
        }
        default: {
          return acc;
        }
      }
    }

    return id === 'gmate' && !isAvailable
      ? acc
      : {
          ...acc,
          [key]: id
            ? {
                ...value,
                loading: false,
                connected,
                isAvailable,
                semiConnected,
                isReconnected,
              }
            : enrichApps(socialAccounts, availableApps, value),
        };
  }, {} as AH$Apps);

// It is necessary to use this func as profile.displayContacts.email doesn't include
// unopened contacts
export const getEmailsFromContacts = (
  contactsById: AH$ProfileContactsById,
  contactsIdByType: Map<string, Array<number>> = new Map([['email', []]])
): Array<{ type: 'corporate' | 'personal'; value: string }> =>
  (contactsIdByType.get('email') || []).reduce<
    Array<{ type: 'corporate' | 'personal'; value: string }>
  >(
    (filteredEmails, contactId) =>
      (contactsById[contactId] as AH$ProfileContact).valid
        ? [
            ...filteredEmails,
            {
              value: contactsById[contactId]?.value,
              type: contactsById[contactId]?.sourceInfo?.corporate ? 'corporate' : 'personal',
            },
          ]
        : filteredEmails,

    []
  );

export const getTelegramsFromContacts = (
  contactsById: AH$ProfileContactsById,
  contactsIdByType: Map<string, Array<number>> = new Map([['telegram', []]])
): Array<{ value: string }> =>
  (contactsIdByType.get('telegram') || []).reduce<Array<{ value: string }>>(
    (filteredTelegrams, contactId) =>
      (contactsById[contactId] as AH$ProfileContact).valid
        ? [
            ...filteredTelegrams,
            {
              value: (contactsById[contactId] as AH$ProfileContact).value,
            },
          ]
        : filteredTelegrams,
    []
  );

export const transformComments = (
  generalComments: Array<AH$ProfileComment> = [],
  folderComments: Array<AH$ProfileFolder> = []
): Array<AH$ProfileComment> => {
  const foldersCurrentComments = folderComments
    .filter(({ accessible }) => accessible)
    .flatMap(({ comments }) => comments);

  return uniqBy(
    [...generalComments, ...foldersCurrentComments].sort(
      (a, b) => Date.parse(get(b, 'createdAt', '')) - Date.parse(get(a, 'createdAt', ''))
    ),
    'id'
  );
};

export const getProfileInitials = (profile: {
  firstName?: string | null;
  lastName?: string | null;
  name?: string | null;
  nick?: string | null;
}): string => {
  const { name, nick, firstName, lastName } = profile;
  const fullName = name || nick || '';
  const foundLastName = fullName.split(' ')[1];

  if (firstName && lastName && firstName[0] === fullName[0]) {
    return firstName[0] + lastName[0];
  }
  if (foundLastName) {
    return fullName[0] + foundLastName[0];
  }
  return fullName.slice(0, 2);
};

export const transformRecipientProfile = (
  profile: API$MailingRecipient['profile']
): AH$Recipient['profile'] => {
  if (typeof profile === 'object') {
    if ((profile as API$MiniProfile).profileID) {
      return {
        ...omit(profile, 'profileID'),
        id: (profile as API$MiniProfile).profileID,
      } as AH$MiniProfile;
    }
  }

  return profile as AH$Recipient['profile'];
};

export function startLoading(node?: HTMLElement): void {
  const body = (document.getElementsByTagName('body') || [])[0];
  const element = node || body;

  if (element) {
    element.className += ' ah-loading';
  }
}

export function stopLoading(node?: HTMLElement): void {
  const body = (document.getElementsByTagName('body') || [])[0];
  const element = node || body;

  if (element) {
    element.className = element.className.replace(' ah-loading', '');
  }
}

export function getUserLocation(location = ''): string {
  return location.split(' ').length > 1 ? `"${location}"` : location;
}

export const generateUTCRange = (): Array<string> => {
  const utcRange = [];

  for (let i = 11; i > 0; --i) {
    utcRange.push(`-${`0${i}`.slice(-2)}`);
  }
  for (let i = 0; i < 15; ++i) {
    utcRange.push(`+${`0${i}`.slice(-2)}`);
  }

  return utcRange;
};

export const transformExampleTemplates = (
  templates: Array<API$ExampleTemplate>
): Array<AH$ExampleTemplate> =>
  templates.map((template) => ({ ...camelKeys(template), isExample: true }));

export const isGMSCompany = (user: AH$User): boolean => user.company.companyName === 'GMS Services';
export const isAHCompany = (user: AH$User): boolean => user.company.companyId === 3;

export const isAdmin = (role: AH$User['role']): boolean =>
  ['admin', 'admin_without_search'].includes(role);

export const isAdminWithoutSearch = (role: AH$User['role']): boolean =>
  role === 'admin_without_search';

export const isManager = (role: AH$User['role']): boolean =>
  ['super_user', 'sales_manager'].includes(role);

export const isHiringManager = (role?: AH$User['role']): boolean => role === 'hm';

export const homePageForCurrentUser = (user: AH$User): string => {
  const {
    canSearch,
    role,
    company: { hasActiveLicense, isExtCompany },
  } = user;

  if (!hasActiveLicense && !isExtCompany) {
    return '/license-expired/';
  }

  const homePage: Record<AH$User['role'], string> = {
    admin: '/',
    admin_without_search: '/company/employees/',
    worker: (() => {
      if (isExtCompany) {
        return '/extension/';
      }
      return canSearch ? '/' : '/menu/';
    })(), // TODO: на бэке добавить роли worker_without_search, worker_ext_company
    hm: '/folders/',
    sales_manager: '/',
    super_user: '/',
    service_admin: '/',
  };

  return homePage[role];
};

export const bodyScrollDisable = (): void => {
  document.body.style.overflow = 'hidden';
};

export const bodyScrollEnable = (): void => {
  // Открытая модалка бутстрап ставит overflow: hidden и тогда его не надо тут перетирать
  if (!document.body.className.includes('modal-open')) {
    document.body.style.overflow = '';
  }
};

export const maxRatingOnResource = (ratings: Array<AH$SkillRating> | null): AH$SkillRating =>
  isEmpty(ratings) ? ({} as AH$SkillRating) : (maxBy(ratings, 'normRating') as AH$SkillRating);

export const skillResource = (str = '', separator = '_'): string => str.split(separator)[0];

export function parseTelegramSource(url: string): { source: string; type: string } {
  const urlMatch = url.match(/matched-tg\/(.*)\/(.*)/) || [];

  return {
    type: urlMatch[1],
    source: urlMatch[2],
  };
}

export const addRootEventListener = <K extends keyof HTMLElementEventMap>(
  event: K,
  listener: (ev: HTMLElementEventMap[K]) => any,
  options?: boolean | AddEventListenerOptions
): void => {
  if (APP_NODE) APP_NODE.addEventListener(event, listener, options);
};

export const removeRootEventListener = <K extends keyof HTMLElementEventMap>(
  event: K,
  listener: (ev: HTMLElementEventMap[K]) => any,
  options?: boolean | EventListenerOptions
): void => {
  if (APP_NODE) APP_NODE.removeEventListener(event, listener, options);
};

export function formatFileSize(size: number): string {
  const i = Math.floor(Math.log(size) / Math.log(1024));

  return `${(size / 1024 ** i).toFixed([0, 1].includes(i) ? 0 : 1)} ${
    ['B', 'KB', 'MB', 'GB', 'TB'][i]
  }`;
}

export const mergeRefs =
  <T extends HTMLElement>(...refs: Array<Ref<T>>) =>
  (value: T): void => {
    for (let i = 0; i < refs.length; i += 1) {
      const ref = refs[i];

      if (typeof ref === 'function') {
        ref(value);
      } else if (ref) {
        (ref as MutableRefObject<T>).current = value;
      }
    }
  };

export const getLinkForHelpCenter = (
  whiteLabelName: 'jointalent' | 'matchy' | 'amazinghiring',
  links: {
    amazinghiring: string;
    jointalent: string;
    matchy: string;
  } = {
    matchy: LINK_FOR_HELP_CENTER_MATCHY,
    amazinghiring: LINK_FOR_HELP_CENTER,
    jointalent: LINK_FOR_HELP_CENTER,
  }
): string => links[whiteLabelName];

export const getVacancyInfoForBadge = ({
  stage_name,
  status,
  updated_at,
  vacancy_name,
}: {
  stage_name?: string;
  status?: string;
  updated_at?: string | number;
  vacancy_name?: string;
}): string | null => {
  const vacancyInfoArray = [];

  if (updated_at) {
    vacancyInfoArray.push(moment(updated_at).format('D MMMM YYYY'));
  }

  if (status) {
    vacancyInfoArray.push(status);
  }

  if (stage_name) {
    vacancyInfoArray.push(stage_name);
  }

  if (vacancy_name) {
    vacancyInfoArray.push(vacancy_name);
  }

  return vacancyInfoArray.length ? vacancyInfoArray.join(' | ') : null;
};

export const copyTextToClipboard = (text: string, cb = noop): void => {
  if (navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log('Text successfully copied to clipboard');
        cb(true);
      })
      .catch((err) => {
        console.error('Error in copying text: ', err);
        cb(false);
      });
  } else {
    const textArea = document.createElement('textarea');

    textArea.value = text;
    textArea.style.position = 'fixed';
    textArea.style.left = '-999999px';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    let result;

    try {
      const successful = document.execCommand('copy');

      if (successful) {
        console.log('Text successfully copied to clipboard');
        result = true;
      } else {
        console.error('Unable to copy text');
        result = false;
      }
    } catch (err) {
      console.error('Error in copying text: ', err);
      result = false;
    }

    document.body.removeChild(textArea);

    cb(result);
  }
};

export const mergeProfileDisplayContacts = (
  obj1: AH$ProfileDisplayContacts,
  obj2: AH$ProfileDisplayContacts
): AH$ProfileDisplayContacts => {
  const result: AH$ProfileDisplayContacts = { ...obj1 };

  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj2) {
    if (Object.prototype.hasOwnProperty.call(obj2, key)) {
      if (result[key]) {
        const existingValues = new Set(result[key].map((item) => item.value));

        const uniqueItems = obj2[key].filter((item) => !existingValues.has(item.value));

        result[key] = result[key].concat(uniqueItems);
      } else {
        result[key] = [...obj2[key]];
      }
    }
  }

  return result;
};

export const hasUniqContacts = (
  oldContacts: AH$ProfileDisplayContacts,
  newContacts: AH$ProfileDisplayContacts
): boolean => {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in newContacts) {
    if (Object.prototype.hasOwnProperty.call(newContacts, key)) {
      if (oldContacts[key]) {
        const existingValues = new Set(oldContacts[key].map((item) => item.value));

        const hasNewValue = newContacts[key].some((item) => !existingValues.has(item.value));

        if (hasNewValue) {
          return true;
        }
      } else {
        // Если ключа нет в старых контактах, значит все новые контакты под этим ключом уникальны
        return true;
      }
    }
  }

  return false;
};
