import parse from 'parse-link-header';

import { getSessionUuidHeaders } from 'lib/sessionUuid';
import { addGetParams } from 'lib/url';
import { getCSRFToken } from 'lib/utils';

import 'whatwg-fetch';

/**
 * Response error
 * @param {Response} response
 * @constructor
 */
interface ResponseErrorType {
  message: string;
  name: string;
  response?: Response;
  stack?: string;
}

function ResponseError(this: ResponseErrorType, response: Response): void {
  this.name = 'ResponseError';
  this.message = response.statusText;
  this.response = response;
  this.stack = new Error().stack;
}

ResponseError.prototype = new Error();

/**
 * Makes requests
 * @param {string} [url] Final endpoint
 * @param {string} [path] Resource path
 * @param {Object} [params] Request params
 * @param {string} [method=get] Request method
 * @param {string} [dataType] Request data type
 * @param {Object} [data] Request data
 * @returns {Promise}
 */
function req({
  url,
  path = '',
  params,
  method = 'GET',
  dataType = '',
  data = {},
  signal = null,
  mock = false,
  headers = {},
}: {
  data?: Record<string, any> | null;
  dataType?: string;
  headers?: Record<string, any>;
  method?: string;
  mock?: boolean;
  params?: Record<string, any> | null;
  path?: string;
  signal?: string | null;
  url?: string;
}): Promise<Response> {
  const endpoint =
    url || addGetParams((mock ? 'http://localhost:8080' : window.location.origin) + path, params);
  const csrftoken = getCSRFToken();
  const fetchParams: Record<string, any> = {
    credentials: 'include',
    headers: {
      'X-CSRFToken': csrftoken,
      Accept: 'application/json; version=5.1',
      'X-previous-url': document.referrer,
      ...getSessionUuidHeaders(),
      ...headers,
    },
    method,
  };

  if (dataType === 'json') {
    fetchParams.headers['Content-Type'] = 'application/json; charset=UTF-8';
    fetchParams.body = JSON.stringify(data);
  } else if (dataType === 'formUrlencoded') {
    fetchParams.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
    fetchParams.body = data;
  } else if (dataType === 'formData') {
    fetchParams.body = data;
  }

  if (signal) {
    fetchParams.signal = signal;
  }

  return fetch(endpoint, fetchParams);
}

/**
 * Checks response status
 * @param {Object} response Request response
 * @returns {Promise.<Response, ResponseError>}
 */
function checkResponseStatus(response: Response): Promise<Response> {
  if (response.status >= 200 && response.status <= 300) {
    return Promise.resolve(response);
  }
  if (response.status === 401) {
    const { origin, pathname, search } = window.location;

    window.location.assign(`${origin}/login/?next=${pathname}${search}`);
  }
  return Promise.reject(
    new (ResponseError as any as { new (response: Response): ResponseErrorType })(response)
  );
}

/**
 * Checks old API response status
 * @deprecated
 * @param {Object} json
 * @returns {(Object|Error)}
 */
function checkJsonStatus(json: Record<string, any>): Record<string, any> {
  if (json.status === 'success') {
    return json.data;
  }

  throw new Error('API error');
}

/**
 * Parses next/prev links from headers
 * @param res
 * @returns {Object}
 */
function handleHeaderLink(res: Response): Record<string, any> | null {
  return parse(res.headers.get('link') as string);
}

/**
 * Store GDPR flag
 * @param res
 * @returns {Object}
 */
function handleGdprFlag(res: Response): AH$GdprFilters {
  return {
    gender: res.headers.get('x-ah-gdpr-facet-gender') === 'true',
    diversity: res.headers.get('x-ah-gdpr-facet-diversity') === 'true',
    age: res.headers.get('x-ah-gdpr-facet-age') === 'true',
  };
}

/**
 * Parses JSON from response
 * @param {Object} res Successfull response object
 * @returns {Promise.<Object, Error>}
 */
function handleJson(res: Response): Promise<any> {
  return res.text().then((text) => (text ? JSON.parse(text) : {}));
}

function handleProfileReferer(res: Response): string | null {
  return res.headers.get('X-AH-Profile-Referer');
}

/**
 * @typedef {Object} ResponsePayload
 * @property {Response} response Raw response
 * @property {(Object|Array)} payload Response parsed JSON
 * @property {Object} pages Pagination
 */
/**
 * Handles API response
 * @param {Response} res
 * @returns {Promise.<ResponsePayload>}
 */
function handleResponse(res: Response): Promise<{
  gdpr: AH$GdprFilters;
  pages: Record<string, any> | null;
  payload: any;
  profileReferer: string | null;
  response: Response;
}> {
  return Promise.all([
    res,
    handleJson(res),
    handleHeaderLink(res),
    handleGdprFlag(res),
    handleProfileReferer(res),
  ]).then(([response, payload, pages, gdpr, profileReferer]) => ({
    response,
    payload,
    pages,
    gdpr,
    profileReferer,
  }));
}

/**
 * Makes GET request
 * @param {string} path Endpoint path
 * @param {Object} [params] Request parameters
 * @param {Object} [signal] Signal
 * @returns {Promise.<ResponsePayload, ResponseError>}
 */
export function get(
  path: string,
  params?: Record<string, any>,
  signal: null | string = null,
  mock = false,
  headers?: Record<string, any>
): Promise<any> {
  return req({ path, params, signal, mock, headers })
    .then(checkResponseStatus)
    .then(handleResponse);
}

/**
 * Makes GET request by given url without any processing
 * @param {string} path
 * @returns {Promise.<ResponsePayload, ResponseError>}
 */
export function directGet(path: string): Promise<any> {
  return req({ url: window.location.origin + path })
    .then(checkResponseStatus)
    .then(handleResponse);
}

/**
 * Creates resource
 * @param {string} path Endpoint path
 * @param {Object} data Request data
 * @param {Object} [params] Request parameters
 * @param {Object} [dataType] Request data
 * @returns {Promise.<ResponsePayload, ResponseError>}
 */
export function post(
  path: string,
  {
    data,
    params,
    dataType = 'json',
    direct = false,
  }: {
    data?: Record<string, any>;
    dataType?: string;
    direct?: boolean;
    params?: Record<string, any>;
  } = {}
): Promise<any> {
  const requestParams = {
    method: 'POST',
    dataType,
    data,
    ...(direct
      ? { url: window.location.origin + path }
      : {
          path,
          params,
        }),
  };

  return req(requestParams).then(checkResponseStatus).then(handleResponse);
}

/**
 * Modifies resources
 * @param {string} path
 * @param {Object} params
 * @param {Object} data
 * @returns {Promise.<ResponsePayload, ResponseError>}
 */
export function patch(
  path: string,
  { params, data }: { data?: Record<string, any>; params?: Record<string, any> } = {}
): Promise<any> {
  const requestParams = {
    method: 'PATCH',
    dataType: 'json',
    path,
    params,
    data,
  };

  return req(requestParams).then(checkResponseStatus).then(handleResponse);
}

export function put(
  path: string,
  {
    params,
    data,
    dataType = 'json',
  }: { data?: Record<string, any>; dataType?: string; params?: Record<string, any> }
): Promise<any> {
  const requestParams = {
    method: 'PUT',
    dataType,
    path,
    params,
    data,
  };

  return req(requestParams).then(checkResponseStatus).then(handleResponse);
}

/**
 * Deletes resource
 * @param {string} path Resource path
 * @param params
 * @param {Object} [data]
 * @returns {Promise.<Response, ResponseError>}
 */
export function del(
  path: string,
  params?: Record<string, any> | null,
  data?: Record<string, any> | null
): Promise<any> {
  return req({
    method: 'DELETE',
    path,
    params,
    dataType: 'json',
    data,
  })
    .then(checkResponseStatus)
    .then(handleResponse);
}

/**
 * Retrieve data
 * @deprecated
 * @param path
 * @param params
 * @param signal
 * @returns {Promise.<Object, Error>}
 */
export function oldApiGet(path: string, params?: Record<string, any>, signal = null): Promise<any> {
  return get(path, params, signal)
    .then(({ payload }) => payload)
    .then(checkJsonStatus);
}

/**
 * Handles errors
 * @param {Error} error Request error
 */
export function onError(error: Error): void {
  console.error('API error', error); // eslint-disable-line no-console
}

export function download(
  path: string,
  {
    data,
    params,
    dataType = 'json',
  }: { data?: Record<string, any>; dataType?: string; params?: Record<string, any> } = {}
): Promise<any> {
  const requestParams = {
    method: 'POST',
    dataType,
    path,
    params,
    data,
  };

  return req(requestParams)
    .then(checkResponseStatus)
    .then((response) => {
      const fileName = ((response.headers.get('content-disposition') || '').match(
        /filename="(.*)"/
      ) || [])[1];

      response.blob().then((blob) => {
        const link = document.createElement('a');

        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        if (document.body) {
          document.body.appendChild(link);
        }
        link.click();
        link.remove();
      });
    });
}

export function responseMock(payload: any, response?: any): Promise<any> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        response: response || {},
        payload,
        pages: 1,
        gdpr: { age: false, gender: false, diversity: false },
      });
    }, 300);
  });
}
