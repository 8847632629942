import {
  AnyAction,
  createAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import { EditorState, Entity, EntityInstance, Modifier, SelectionState } from 'draft-js';
import { List, Map, Record } from 'immutable';
import {
  debounce,
  find,
  first,
  forIn,
  get,
  groupBy,
  isEmpty,
  isUndefined,
  mapValues,
  merge,
  noop,
  pick,
  reverse,
  sortBy,
  toPairs,
} from 'lodash';

import { AH$RouterLocation } from 'types';
import { FETCHING_STATUSES, FIELDS } from 'constants/globals';
import * as api from 'lib/api';
import * as apiV6 from 'lib/apiV6';
import { stringify } from 'lib/query';
import { noTagToSkillSuggestType } from 'lib/suggestions';
import { startLoading, stopLoading } from 'lib/utils';
import { NavigateFunction } from 'hooks/useNavigate';
import { getErrorHandler } from 'slices/app';
import { getRealtimeCount } from 'slices/filters';
import { transformFolder } from 'components/Folders/lib';
import Decorator from 'components/QueryEditor/CompositeDecorator';
import {
  enrichEntitiesData,
  getEditorStateWhithApplyedEntity,
  getEditorStateWithNewEntities,
  getQueriesFromUrl,
  getTokens,
  normalizeSuggestionKey,
  quoteCollocation,
  transformToUrl,
} from 'components/QueryEditor/lib';

const SLICE_NAME = 'searchForm';

export type RawQuery = {
  editorState: EditorState;
  excluded: boolean;
  field: Map<string, any>;
};

export type EnrichedEntity = {
  entity: Entity;
  entityKey: string;
  field: Map<string, any>;
  hasCaretOnEnd: boolean;
  key: number;
  length: number;
  offset: number;
  text: string;
  type: AH$FormEntityType;
  isExcluded?: boolean;
  skillRange?: number;
};

export enum SearchMetricsType {
  search = 'search',
  ti = 'ti',
  similar = 'similar',
  folder = 'folder',
  ext = 'ext',
  sequence = 'seq',
  recommended = 'recommended',
}

export type Query = RawQuery & { [key: string]: any };

export type SearchFormState = {
  readonly activeQuery: string | null;
  readonly activeSmartFolder: AH$ActiveSmartFolder | null;
  readonly aiStep: {
    readonly data: AH$AISearchStep | null;
    readonly status: $Values<typeof FETCHING_STATUSES>;
  };
  readonly cachedQueries: List<Query> | null;
  readonly locationRangeSuggestion: {
    id: string;
    text: string;
    isCity?: boolean;
  } | null;
  readonly metaQueries: {
    readonly data: AH$MetaQueries | null;
    status: $Values<typeof FETCHING_STATUSES>;
  };
  readonly queries: List<Query>;
  readonly queryTimeoutIds: Array<number>;
  readonly savedQueries: {
    readonly data: Array<AH$SavedQuery>;
    readonly pages: API$Pages | null;
    status: $Values<typeof FETCHING_STATUSES>;
  };
  readonly searchFormType: AH$SearchFormType | null;
  readonly searchHistory: {
    readonly data: Array<AH$SearchHistoryQuery>;
    readonly pages: API$Pages | null;
    status: $Values<typeof FETCHING_STATUSES>;
  };
  readonly searchMetricsType: SearchMetricsType | null;
  readonly searchQueryId: number | null;
  readonly smartFolderSearchHistory: {
    readonly data: Array<AH$SearchHistoryQuery>;
    readonly pages: API$Pages | null;
    status: $Values<typeof FETCHING_STATUSES>;
  };
  readonly suggestions: Map<string, Map<string, Array<AH$Suggestion>>>;
  readonly suggestionsStatus: $Values<typeof FETCHING_STATUSES>;
};

export type TextApplyedPayload = {
  queryKey: number;
  selection: SelectionState;
  suggestion: {
    id: string;
    text: string;
    type: AH$FormEntityType;
    isCity?: boolean;
    isMainSkill?: boolean;
  };
  whithSynonym: boolean;
  isV2?: boolean;
};
export type FieldChangedPayload = {
  excluded: boolean;
  field: AH$QueryField | Map<string, any>;
  queryKey: number;
  isV2?: boolean;
};
export type QueryAddedPayload = {
  excluded: boolean;
  field: AH$QueryField;
};

export type FieldSetErrorPayload = {
  editorState?: EditorState;
  queryKey?: number;
};

type Dispatch = ThunkDispatch<{ searchForm: SearchFormState }, unknown, AnyAction>;

export const makeQuery = Record({
  editorState: EditorState.createEmpty(Decorator),
  field: FIELDS.get('text'),
  excluded: false,
});

export const getSearchFormInitialState = (): SearchFormState => ({
  queries: List<Query>(),
  suggestions: Map<string, Map<string, Array<AH$Suggestion>>>(),
  suggestionsStatus: FETCHING_STATUSES.SUCCESS,
  metaQueries: {
    status: FETCHING_STATUSES.LOADING,
    data: null,
  },
  cachedQueries: null,
  searchHistory: {
    data: [],
    status: FETCHING_STATUSES.LOADING,
    pages: null,
  },
  savedQueries: {
    data: [],
    status: FETCHING_STATUSES.LOADING,
    pages: null,
  },
  activeQuery: null,
  aiStep: {
    data: null,
    status: FETCHING_STATUSES.LOADING,
  },
  locationRangeSuggestion: null,
  activeSmartFolder: null,
  searchQueryId: null,
  smartFolderSearchHistory: {
    data: [],
    status: FETCHING_STATUSES.LOADING,
    pages: null,
  },
  queryTimeoutIds: [],
  searchMetricsType: null,
  searchFormType: null,
});

const changeEditorStateAction = createAction<{ editorState: EditorState; queryKey: number }>(
  `${SLICE_NAME}/changeEditorState`
);

export const changeEditorState =
  ({
    queryKey,
    editorState,
    force = false,
    isV2 = false,
  }: {
    editorState: EditorState;
    force?: boolean;
    isV2?: boolean;
    queryKey?: number;
  }) =>
  (dispatch: Dispatch, getState: () => { searchForm: SearchFormState }) => {
    if (isUndefined(queryKey)) return;

    let nextEditorState = editorState;
    const { editorState: prevEditorState, field } =
      getState().searchForm.queries.get(queryKey) || {};
    const getText = (editorState: EditorState) => editorState.getCurrentContent().getPlainText();
    const text = getText(nextEditorState);
    const prevText = prevEditorState ? getText(prevEditorState) : '';
    const isTextChange = text !== prevText;
    const isAI = Boolean(find(enrichEntitiesData(editorState), { type: 'AI' }));

    if ((isTextChange || force) && !isAI) {
      const marksByOffset = enrichEntitiesData(editorState).reduce(
        (acc, { entityKey, offset, text }: EnrichedEntity) => {
          const { whithSynonym } = editorState.getCurrentContent().getEntity(entityKey).getData();

          return whithSynonym
            ? acc
            : {
                ...acc,
                [offset]: text,
              };
        },
        {}
      );

      nextEditorState = getEditorStateWithNewEntities({
        queryKey,
        editorState: nextEditorState,
        tokens: getTokens({
          field,
          text,
          isV2,
          marksByOffset,
        }),
        prevEditorState,
        force,
        isV2,
      });
    }

    dispatch(
      changeEditorStateAction({
        queryKey,
        editorState: nextEditorState,
      })
    );
  };

const suggestionsFetching = createAction<{ [key: string]: Array<string> }>(
  `${SLICE_NAME}/suggestionsFetching`
);

const suggestionsFetched = createAction<AH$TypeToSuggest>(`${SLICE_NAME}/suggestionsFetched`);

const suggestionFetched = createAction<{
  field: string;
  suggestions: Array<AH$Suggestion>;
  text: string;
}>(`${SLICE_NAME}/suggestionFetched`);

const termLocalized = createAction<{
  field: string;
  suggestion: AH$Suggestion;
  text: string;
  isV2?: boolean;
}>(`${SLICE_NAME}/termLocalized`);

export const fetchSuggestions =
  ({
    type2terms,
    forceLocalize = false,
    isV2 = false,
  }: {
    type2terms: AH$Type2term;
    forceLocalize?: boolean;
    isV2?: boolean;
  }) =>
  async (dispatch: Dispatch, getState: () => { searchForm: SearchFormState }) => {
    const transformedType2terms = noTagToSkillSuggestType(type2terms);
    const formattedType2terms = mapValues(transformedType2terms, (arr) => arr?.map((v) => v.text));
    const extendedType2terms = {
      ...transformedType2terms,
      ...(transformedType2terms.skill && { text: transformedType2terms.skill }),
    };

    dispatch(
      suggestionsFetching({
        ...formattedType2terms,
        ...(formattedType2terms.skill && { text: formattedType2terms.skill }),
      } as Record<string, Array<string>>)
    );

    try {
      const {
        payload: { type2suggests: rawType2suggests },
      } = await api.suggestsGet(formattedType2terms);
      const transformedRawType2suggests = merge(rawType2suggests, {
        text: rawType2suggests.skill,
      });
      const type2suggests = mapValues(transformedRawType2suggests, (text2suggests) =>
        mapValues(text2suggests, (suggests, text) =>
          suggests.map((suggest) =>
            suggest.id === `id-${text.toLowerCase()}` ? { ...suggest, isKnown: true } : suggest
          )
        )
      );

      dispatch(suggestionsFetched(type2suggests));

      forIn(type2suggests, (text2suggests, field) => {
        forIn(text2suggests, (suggestions: Array<AH$Suggestion>, text) => {
          const isExact = extendedType2terms[field as keyof AH$Type2term]?.find(
            ({ isExact }) => isExact
          )?.isExact;

          if (forceLocalize && !['and', 'or'].includes(text.toLowerCase()) && !isExact) {
            const suggestion = first(suggestions);

            if (
              !isEmpty(suggestion) &&
              ((suggestion.isKnown && (!isV2 || field === 'location')) ||
                (text === suggestion.id && isV2))
            ) {
              dispatch(termLocalized({ field, text, suggestion, isV2 }));
              dispatch(
                suggestionFetched({
                  field,
                  text: normalizeSuggestionKey(suggestion.name),
                  suggestions,
                })
              );
            }
          }
        });
      });

      getState().searchForm.queries.forEach((query, queryKey) => {
        if (query) {
          const { editorState, field } = query;

          if (Object.keys(type2suggests).includes(field.get('suggest'))) {
            dispatch(changeEditorState({ queryKey, editorState, force: true, isV2 }));
          }
        }
      });
    } catch (e) {
      getErrorHandler()(e);
    }
  };

const debouncedFetchSuggestions = debounce(
  (
    dispatch: Dispatch,
    payload: {
      type2terms: AH$Type2term;
      forceLocalize?: boolean;
      isV2?: boolean;
    }
  ) => {
    dispatch(fetchSuggestions(payload));
  },
  250
);

export const debounceFetchSuggestions =
  (payload: { type2terms: AH$Type2term; forceLocalize?: boolean; isV2?: boolean }) =>
  (dispatch: Dispatch) => {
    debouncedFetchSuggestions(dispatch, payload);
  };

const searchFormFilled = createAction<SearchFormState['queries']>(`${SLICE_NAME}/searchFormFilled`);

export const fillSearchForm =
  ({
    queries: payload,
    isV2 = false,
  }: { isV2?: boolean; queries?: SearchFormState['queries'] } = {}) =>
  (dispatch: Dispatch, getState: () => { searchForm: SearchFormState }): void => {
    const aiQueries = getState().searchForm.metaQueries.data || undefined;
    const queries = payload || getQueriesFromUrl(undefined, aiQueries, isV2);

    dispatch(searchFormFilled(queries));

    queries.forEach((query) => {
      const editorState = get(query, 'editorState') as EditorState;
      const field = get(query, 'field') as Map<string, any>;
      const enrichedEntitiesData = enrichEntitiesData(editorState);

      enrichedEntitiesData.forEach(({ text, entity }: { entity: EntityInstance; text: string }) => {
        if (!['and', 'or'].includes(text.toLowerCase()) && entity.getData().whithSynonym) {
          const {
            searchForm: { suggestions },
          } = getState();
          const normalizedText = normalizeSuggestionKey(text);
          const suggestion = first(
            suggestions.getIn([field.get('suggest'), normalizedText]) || []
          ) as AH$Suggestion;

          if (
            !isEmpty(suggestion) &&
            (suggestion.isKnown || (normalizedText === suggestion.id && isV2))
          ) {
            dispatch(
              termLocalized({ field: field.get('suggest') as string, text, suggestion, isV2 })
            );
          }
        }
      });
    });
  };

type SearchQueryParam = {
  excluded: boolean;
  field: string;
  rawText: string;
  notBoolean?: boolean;
};

export const searchByQuery =
  ({
    navigate,
    location,
    query,
    isV2 = false,
  }: {
    location: AH$RouterLocation;
    navigate: NavigateFunction;
    query: SearchQueryParam;
    isV2?: boolean;
  }) =>
  (dispatch: Dispatch, getState: () => { searchForm: SearchFormState }): void => {
    const {
      searchForm: { queries, suggestions, activeSmartFolder },
    } = getState();

    const isHomePage = window.location.pathname === '/';
    const q: AH$SearchQueryParamQ = transformToUrl(queries, suggestions, isV2);
    const isExistingField = q.find(({ field }) => field === query.field);
    const firstQueryIndex = q.findIndex(({ field }) => field === query.field);
    const normalizedQueries = [...q, query];
    const v2NormalizedQueries = isExistingField
      ? q.map((q, index) => {
          // надо в MarketInsights, когда несколько одинаковых полей в форме skillAll / skillAny
          if (q.field === query.field && index === firstQueryIndex) {
            return {
              ...q,
              notBoolean: isV2,
              rawText: `${q.rawText},${query.excluded ? '-' : ''}${query.rawText}`,
            };
          }

          return q;
        })
      : [
          ...q,
          query.excluded
            ? {
                ...query,
                excluded: false,
                notBoolean: isV2,
                rawText: `-${query.rawText}`,
              }
            : query,
        ];

    const search = stringify({
      q: isV2 ? v2NormalizedQueries : normalizedQueries,
      f: {
        ...location.profilesQuery.f,
        ...(isHomePage && activeSmartFolder
          ? { '-smartFolderHits': new Set([activeSmartFolder.id]) }
          : undefined),
      },
      s: location.profilesQuery.s,
      folderId: activeSmartFolder ? activeSmartFolder.id : undefined,
    });

    navigate({
      ...(isHomePage ? { pathname: '/profiles/' } : undefined),
      search: `?${search}`,
    });

    dispatch(fillSearchForm({ isV2 }));
    if (isV2) {
      dispatch(getRealtimeCount());
    }
  };

export const loadMetaQueries = createAsyncThunk(
  `${SLICE_NAME}/loadMetaQueries`,
  async ({ onSuccess = noop }: { onSuccess?: () => void }, { rejectWithValue }) => {
    try {
      const { payload } = await apiV6.enabledMetaQueriesGet();

      onSuccess();
      return groupBy(sortBy(payload, ['order']), 'group') as unknown as AH$MetaQueries;
    } catch (e) {
      getErrorHandler()(e);
      return rejectWithValue(e);
    }
  }
);

export const receiveSearchHistory = createAsyncThunk(
  `${SLICE_NAME}/receiveSearchHistory`,
  async (
    {
      onSuccess,
      params = {},
    }: {
      onSuccess?: () => void;
      params?: {
        creator_id?: string | null;
        date_range?: string | null;
        filter_smart_folders?: boolean;
        limit?: number;
        offset?: number;
      };
    },
    { rejectWithValue }
  ) => {
    try {
      const {
        payload: { results, ...pages },
      } = await api.historyGet({
        ...params,
        filter_smart_folders: params.filter_smart_folders !== false,
      });

      onSuccess?.();
      return { data: results, pages };
    } catch (e) {
      getErrorHandler()(e);
      return rejectWithValue(e);
    }
  }
);

export const hideSearchHistoryEntry = createAsyncThunk(
  `${SLICE_NAME}/hideSearchHistoryEntry`,
  async (query: string, { dispatch }) => {
    try {
      await api.historyEntryHide(query);
      dispatch(receiveSearchHistory({}));
    } catch (e) {
      getErrorHandler()(e);
    }
  }
);

export const receiveSavedQueries = createAsyncThunk(
  `${SLICE_NAME}/receiveSavedQueries`,
  async (params: API$PaginationParams, { rejectWithValue }) => {
    try {
      const {
        payload: { results, ...pages },
      } = await api.getSavedQueries(params);

      return {
        data: results,
        pages,
      };
    } catch (e) {
      getErrorHandler()(e);
      return rejectWithValue(e);
    }
  }
);

export const saveSearchQuery = createAsyncThunk(
  `${SLICE_NAME}/saveSearchQuery`,
  async (
    { name, description, query }: { description: string; name: string; query: string },
    { dispatch }
  ) => {
    try {
      await api.querySave({ name, description, query });
      dispatch(receiveSavedQueries({}));
    } catch (e) {
      getErrorHandler()(e);
    }
  }
);

export const deleteSearchQuery = createAsyncThunk(
  `${SLICE_NAME}/deleteSearchQuery`,
  async (id: number, { dispatch }) => {
    try {
      await api.queryDelete(id);
      dispatch(receiveSavedQueries({}));
    } catch (e) {
      getErrorHandler()(e);
    }
  }
);

export const setSmartFolder = createAction<AH$ActiveSmartFolder | null>(
  `${SLICE_NAME}/setSmartFolder`
);

export const getSmartFolderQuery = createAsyncThunk(
  `${SLICE_NAME}/getSmartFolderQuery`,
  async (
    {
      folder,
      onSuccess,
    }: {
      folder: AH$ActiveSmartFolder;
      onSuccess: (query: AH$SearchHistoryQuery) => Promise<void>;
    },
    { dispatch }
  ) => {
    startLoading();

    try {
      const { payload } = await apiV6.smartFolderQueryGet(folder.id);

      dispatch(setSmartFolder(folder));
      await onSuccess(payload);
      stopLoading();
    } catch (e) {
      getErrorHandler()(e);
    }
  }
);

export const getSmartFolderHistory = createAsyncThunk(
  `${SLICE_NAME}/getSmartFolderHistory`,
  async (
    { folderId, params }: { folderId: number; params?: API$PaginationParams },
    { rejectWithValue }
  ) => {
    try {
      const {
        payload: { results, ...pages },
      } = await apiV6.smartFolderHistoryGet(folderId, params);

      return {
        data: results,
        pages,
      };
    } catch (e) {
      getErrorHandler()(e);
      return rejectWithValue(e);
    }
  }
);

const clearQueryTimeoutIdsAction = createAction(`${SLICE_NAME}/clearQueryTimeoutIds`);

export const clearQueryTimeoutIds =
  () => (dispatch: Dispatch, getState: () => { searchForm: SearchFormState }) => {
    getState().searchForm.queryTimeoutIds.forEach((id) => clearTimeout(id));
    dispatch(clearQueryTimeoutIdsAction());
  };

export const getSmartFolder = createAsyncThunk(
  `${SLICE_NAME}/getSmartFolder`,
  async (id: number, { dispatch }) => {
    try {
      const { payload } = await apiV6.folderGet(id);

      dispatch(setSmartFolder(transformFolder(payload).folder));
    } catch (e) {
      getErrorHandler()(e);
    }
  }
);

const searchFormSlice = createSlice({
  name: SLICE_NAME,
  initialState: getSearchFormInitialState(),
  reducers: {
    fieldSetError: (state, action: PayloadAction<FieldSetErrorPayload>) => {
      const { queryKey } = action.payload;
      const { queries: prevQueries } = state;

      if (typeof queryKey === 'number') {
        const { editorState, field } = state.queries.get(queryKey) || {};
        const enrichedEntities = enrichEntitiesData(editorState)
          .filter(
            (entity) =>
              (entity.type === 'APPLIED_TERM' || entity.type === 'TERM') &&
              (entity.text.toUpperCase() === 'AND' || entity.text.toUpperCase() === 'OR')
          )
          .filter((item) => !isEmpty(item));
        const queries = (state.queries.mergeIn as any)([queryKey], {
          field: Map(field).set('hasError', !!enrichedEntities.length),
          editorState,
        });

        state.queries = queries;
      } else {
        state.queries = prevQueries;
      }
    },
    applyText: (state, action: PayloadAction<TextApplyedPayload>) => {
      const { selection, suggestion, whithSynonym, queryKey, isV2 } = action.payload;
      const { text, type } = suggestion;
      const { editorState } = state.queries.get(queryKey) || {};
      const hasFocus = selection.getHasFocus();
      const queryText = (editorState as EditorState).getCurrentContent().getPlainText();
      const caretOffset = (editorState as EditorState).getSelection().getStartOffset();
      const query = state.queries.get(queryKey) || {};
      const enrichedEntities = enrichEntitiesData(query.editorState)
        .filter(
          (entity) =>
            (entity.type === 'APPLIED_TERM' || entity.type === 'TERM') &&
            (entity.text.toUpperCase() === 'AND' || entity.text.toUpperCase() === 'OR')
        )
        .filter((item) => !isEmpty(item));
      const field = query.get('field').set('hasError', !!enrichedEntities.length);
      const isLocationRange = field.get('type') === 'locationRange';
      const isParentOrQuoteApplying = ['( )', '" "'].includes(text);
      const quotedText = isParentOrQuoteApplying ? text : quoteCollocation(text);
      const isSpaceAfter = queryText.slice(caretOffset, caretOffset + 2).includes(' ');
      const isNeedApplySpace =
        (!isSpaceAfter && !isParentOrQuoteApplying && !isLocationRange) || isV2;
      const shouldChangeCursor = isSpaceAfter && !isLocationRange && !isV2;
      const queries = (state.queries.mergeIn as any)([queryKey], {
        editorState: (() => {
          const appliedState = getEditorStateWhithApplyedEntity({
            queryKey,
            field,
            editorState,
            selection,
            text: quotedText,
            type,
            whithSynonym,
            suggestionId: suggestion.id,
            isV2,
            isMainSkill: suggestion.isMainSkill,
          });
          const spaceAppliedState = isNeedApplySpace
            ? getEditorStateWhithApplyedEntity({
                queryKey,
                field,
                editorState: appliedState,
                selection: appliedState.getSelection(),
                text: ' ',
                type: 'SPACE',
                isV2,
              })
            : appliedState;
          const transformedState =
            isV2 && !hasFocus
              ? EditorState.acceptSelection(
                  spaceAppliedState,
                  (spaceAppliedState.getSelection() as any).merge({
                    hasFocus,
                  })
                )
              : spaceAppliedState;

          return isParentOrQuoteApplying || shouldChangeCursor
            ? EditorState.forceSelection(
                spaceAppliedState,
                (spaceAppliedState.getSelection() as any).merge({
                  anchorOffset:
                    (spaceAppliedState.getSelection() as any).anchorOffset -
                    (shouldChangeCursor ? -1 : 2),
                  focusOffset:
                    (spaceAppliedState.getSelection() as any).focusOffset -
                    (shouldChangeCursor ? -1 : 1),
                })
              )
            : transformedState;
        })(),
        field: isLocationRange && !suggestion.isCity ? field.set('locationRange', 0) : field,
      });

      state.queries = queries;
      state.locationRangeSuggestion = isLocationRange
        ? pick(suggestion, ['id', 'text', 'isCity'])
        : state.locationRangeSuggestion;
    },
    changeField: (state, action: PayloadAction<FieldChangedPayload>) => {
      const { queryKey, field, excluded, isV2 } = action.payload;
      const { editorState } = state.queries.get(queryKey) || {};
      const isRequiredSuggestField = get(field, 'type') === 'locationRange';

      let queries;

      //  перезатирает значение в редакторе v2
      if (isRequiredSuggestField && !isV2) {
        queries = (state.queries.mergeIn as any)([queryKey], {
          field: Map(field),
          excluded: false,
          editorState: EditorState.createEmpty(Decorator),
        });
      } else {
        queries = (state.queries.mergeIn as any)([queryKey], { field: Map(field), excluded });

        enrichEntitiesData(editorState)
          .filter(({ type }: { type: string }) => type === 'TERM' || type === 'APPLIED_TERM')
          .forEach(({ entityKey }: { entityKey: string }) => {
            Entity.mergeData(entityKey, { field: Map(field) });
          });
      }

      state.queries = queries;
    },
    addQuery: (state, action: PayloadAction<QueryAddedPayload>) => {
      const { field, excluded } = action.payload;
      const query = makeQuery({ field: Map(field), excluded }) as unknown as Query;
      const editorState = EditorState.forceSelection(
        query.editorState,
        query.editorState.getSelection()
      );
      const queries = state.queries.push(query.set('editorState', editorState) as any);

      state.queries = queries;
    },
    deleteQuery: (state, action: PayloadAction<number>) => {
      const queries = state.queries.delete(action.payload);

      state.queries = queries;
    },
    cacheForm: (state) => {
      state.cachedQueries = state.queries as List<Query>;
    },
    setActiveQuery: (state, action: PayloadAction<SearchFormState['activeQuery']>) => {
      state.activeQuery = action.payload;
    },
    setSearchQueryId: (state, action: PayloadAction<SearchFormState['searchQueryId']>) => {
      state.searchQueryId = action.payload;
    },
    pushQueryTimeoutId: (state, action: PayloadAction<number>) => {
      state.queryTimeoutIds = [...state.queryTimeoutIds, action.payload];
    },
    setSearchMetricsType: (state, action: PayloadAction<SearchFormState['searchMetricsType']>) => {
      state.searchMetricsType = action.payload;
    },
    setSearchFormType: (state, action: PayloadAction<SearchFormState['searchFormType']>) => {
      state.searchFormType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(changeEditorStateAction, (state, action) => {
        const { queryKey, editorState } = action.payload;
        const queries = (state.queries.mergeIn as any)([queryKey], { editorState });

        state.queries = queries;
      })
      .addCase(suggestionsFetching, (state, action) => {
        // ф-я getSuggestionsByCollocation определяет по initData,
        // что данные запрошены, но ещё не получены
        const initData = null;
        const allSuggestions = toPairs(action.payload).reduce(
          (acc, [field, texts]) =>
            texts.reduce((acc, text) => acc.setIn([field, text.toLowerCase()], initData), acc),
          state.suggestions
        );

        state.suggestions = allSuggestions;
        state.suggestionsStatus = FETCHING_STATUSES.LOADING;
      })
      .addCase(suggestionsFetched, (state, action) => {
        const newSuggestions = Map(mapValues(action.payload, Map)) as Map<string, Map<string, any>>;
        const suggestions = state.suggestions.mergeDeep(newSuggestions);

        state.suggestions = suggestions;
        state.suggestionsStatus = FETCHING_STATUSES.SUCCESS;
      })
      .addCase(suggestionFetched, (state, action) => {
        const { field, text, suggestions } = action.payload;
        const allSuggestions = state.suggestions.setIn([field, text], suggestions);

        state.suggestions = allSuggestions;
      })
      .addCase(termLocalized, (state, action) => {
        const { field, text, suggestion, isV2 } = action.payload;
        const queries = state.queries.map((query?: Query) => {
          if (query && field === query.field.get('suggest')) {
            const enrichedEntitiesData = enrichEntitiesData(query.editorState);
            const editorState = reverse(enrichedEntitiesData).reduce(
              (editorState: EditorState, enrichedEntityData: EnrichedEntity) => {
                if (enrichedEntityData.text.toLowerCase() === text.toLowerCase()) {
                  const selection = (editorState.getSelection() as any).merge({
                    anchorOffset: enrichedEntityData.offset,
                    focusOffset: enrichedEntityData.offset + enrichedEntityData.length,
                  });
                  const isKnown = suggestion.id === text;
                  const quotedText =
                    isKnown || text.startsWith('id-') || text.startsWith('-id-')
                      ? quoteCollocation(suggestion.name)
                      : text;
                  const replacedEndOffset = enrichedEntityData.offset + quotedText.length;
                  const currentContent = (() => {
                    const currentContent = Modifier.replaceText(
                      editorState.getCurrentContent(),
                      selection,
                      quotedText
                    );
                    // тут обогащаем entity различными данными
                    const entity = currentContent.createEntity(
                      isV2 ? 'APPLIED_TERM' : 'TERM',
                      isV2 ? 'IMMUTABLE' : 'MUTABLE',
                      {
                        text: quotedText,
                        field: query.field,
                        whithSynonym: true,
                        isExcluded: enrichedEntityData.isExcluded,
                        isMainSkill: !!suggestion.isMainSkill,
                        skillRange: enrichedEntityData.skillRange || 0,
                        isKnown,
                      }
                    );
                    const entityKey = entity.getLastCreatedEntityKey();

                    return Modifier.applyEntity(
                      currentContent,
                      selection.merge({ focusOffset: replacedEndOffset }),
                      entityKey
                    );
                  })();

                  Entity.mergeData(enrichedEntityData.entityKey, { text });

                  return EditorState.set(editorState, { currentContent });
                }
                return editorState;
              },
              query.editorState
            );

            return query.merge({ editorState });
          }
          return query;
        }) as List<Query>;

        state.queries = queries;
        state.cachedQueries = queries;
        state.locationRangeSuggestion =
          field === 'location'
            ? { ...pick(suggestion, ['id', 'isCity']), text: suggestion.name }
            : state.locationRangeSuggestion;
      })
      .addCase(searchFormFilled, (state, action) => {
        state.queries = action.payload;
      })
      .addCase(loadMetaQueries.pending, (state) => {
        state.metaQueries.status = FETCHING_STATUSES.LOADING;
      })
      .addCase(loadMetaQueries.fulfilled, (state, action) => {
        state.metaQueries = { status: FETCHING_STATUSES.SUCCESS, data: action.payload };
      })
      .addCase(loadMetaQueries.rejected, (state) => {
        state.metaQueries.status = FETCHING_STATUSES.ERROR;
      })
      .addCase(receiveSearchHistory.pending, (state) => {
        state.searchHistory.status = FETCHING_STATUSES.LOADING;
      })
      .addCase(receiveSearchHistory.fulfilled, (state, action) => {
        state.searchHistory = { ...action.payload, status: FETCHING_STATUSES.SUCCESS };
      })
      .addCase(receiveSearchHistory.rejected, (state) => {
        state.searchHistory.status = FETCHING_STATUSES.ERROR;
      })
      .addCase(receiveSavedQueries.pending, (state) => {
        state.savedQueries.status = FETCHING_STATUSES.LOADING;
      })
      .addCase(receiveSavedQueries.fulfilled, (state, action) => {
        state.savedQueries = { ...action.payload, status: FETCHING_STATUSES.SUCCESS };
      })
      .addCase(receiveSavedQueries.rejected, (state) => {
        state.savedQueries.status = FETCHING_STATUSES.ERROR;
      })
      .addCase(setSmartFolder, (state, action) => {
        state.activeSmartFolder = action.payload && {
          ...state.activeSmartFolder,
          ...action.payload,
        };
      })
      .addCase(getSmartFolderHistory.pending, (state) => {
        state.smartFolderSearchHistory.status = FETCHING_STATUSES.LOADING;
      })
      .addCase(getSmartFolderHistory.fulfilled, (state, action) => {
        state.smartFolderSearchHistory = { ...action.payload, status: FETCHING_STATUSES.SUCCESS };
      })
      .addCase(getSmartFolderHistory.rejected, (state) => {
        state.smartFolderSearchHistory.status = FETCHING_STATUSES.ERROR;
      })
      .addCase(clearQueryTimeoutIdsAction, (state) => {
        state.queryTimeoutIds = [];
      });
  },
});

export const {
  applyText,
  changeField,
  addQuery,
  deleteQuery,
  cacheForm,
  setActiveQuery,
  setSearchQueryId,
  pushQueryTimeoutId,
  setSearchMetricsType,
  setSearchFormType,
  fieldSetError,
} = searchFormSlice.actions;
export const searchForm = searchFormSlice.reducer;
