import { ReactElement } from 'react';
import { tokenize } from 'boolean-query';
import {
  ContentState,
  convertToRaw,
  EditorState,
  Entity,
  EntityInstance,
  Modifier,
  SelectionState,
} from 'draft-js';
import { List, Map } from 'immutable';
import {
  camelCase,
  dropRight,
  filter,
  find,
  findLast,
  first,
  flatten,
  flow,
  get,
  head,
  isEmpty,
  isEqual,
  isNil,
  isNumber,
  last,
  map,
  mapValues,
  merge,
  omitBy,
  pick,
  reduce,
  reject,
  reverse,
  tail,
  toInteger,
  trim,
  uniq,
} from 'lodash';

import { AH$RouterLocation } from 'types';
import { FIELDS } from 'constants/globals';
import { createLocation } from 'lib/url';
import { makeQuery, Query, SearchFormState } from 'slices/searchForm';
import Decorator from 'components/QueryEditor/CompositeDecorator';
import { KM_TO_MI } from 'components/SearchForm/LocationRanges';

export const quoteCollocation = (text: string): string =>
  text.indexOf(' ') === -1 ? text : `"${text.replaceAll('"', `'`)}"`;

export const operators = [
  { id: 'OR', name: 'OR' },
  { id: 'AND', name: 'AND' },
  { id: 'NOT', name: 'NOT' },
];

export const normalizeSuggestionKey = (text: string): string =>
  text.startsWith('-id-') || text.startsWith('-') ? text.slice(1) : trim(text, '"').toLowerCase();

/*
 * Для всех полей отделяет locationRange
 *  */
export const extractLocationRange = (
  text: string,
  type: string
): { locationRange: number; text: string } => {
  switch (type) {
    case 'locationRange':
    case 'location': {
      const decodedText = decodeURIComponent(text);
      const matchedText = decodedText.match(/(.*)\+(\d+$)/);

      return {
        text: matchedText ? matchedText[1] : text,
        locationRange: matchedText ? parseInt(matchedText[2], 10) : 0,
      };
    }

    default: {
      return { text, locationRange: 0 };
    }
  }
};
/*
 * Для skillAll отделяет skillRange и текст
 * // Пример использования:
      const result = parseSkillString("information-security+4 mobile-development+5");
      console.log(result); // { text: "information-security mobile-development", skillRange: [4, 5] }
 *  */
export const extractSkillRange = (text: string): { skillRange: number[]; text: string } => {
  const parts = text.split(' ');
  const texts: string[] = [];
  const skillRange: number[] = [];

  parts.forEach((part) => {
    const match = part.match(/(.+)\+(\d+)/);

    if (match) {
      texts.push(match[1]);
      skillRange.push(parseInt(match[2], 10));
    }
  });

  return {
    text: texts.join(' ') || text,
    skillRange,
  };
};

export interface EnrichedEntity {
  entity: EntityInstance;
  entityKey: string;
  field: Map<string, any>;
  hasCaretOnEnd: boolean;
  key: number;
  length: number;
  offset: number;
  text: string;
  type: AH$FormEntityType;
  isExcluded?: boolean;
  isMainSkill?: boolean;
  skillRange?: number;
  suggestionId?: string;
}

/*
  Формирует массив entity с дополнительными данными
*/
export function enrichEntitiesData(editorState: EditorState): Array<EnrichedEntity> {
  const caretOffset = editorState.getSelection().getStartOffset();
  const contentState = editorState.getCurrentContent();
  const queryText = contentState.getPlainText();
  const { entityRanges } = convertToRaw(contentState).blocks[0];
  const hasEndQuote = queryText[caretOffset] === '"';

  return entityRanges.map(({ offset, length, ...entityRange }) => {
    const hasCaretOnEnd = offset + length - Number(hasEndQuote) === caretOffset;
    const entityKey = contentState.getFirstBlock().getEntityAt(offset);
    const entity = contentState.getEntity(entityKey);
    const text = queryText.substr(offset, length);
    const isExcludedId = text.startsWith('-id') || text.startsWith('-');
    const type = entity.getType();
    const { field, isExcluded, suggestionId, isMainSkill, skillRange } = entity.getData();
    const skillRangeFromText = extractSkillRange(text);

    const convertedText = () => {
      if (isExcludedId) {
        return text.slice(1);
      }
      if (field?.get('type') === 'skillAll') {
        return text.replaceAll(/\+(\d+$)/g, '');
      }

      return text;
    };

    return {
      ...entityRange,
      hasCaretOnEnd,
      entityKey,
      length,
      offset,
      entity,
      text: convertedText(),
      type: type as AH$FormEntityType,
      field,
      isExcluded: isExcluded || isExcludedId,
      suggestionId,
      isMainSkill,
      skillRange: skillRange || skillRangeFromText.skillRange[0] || 0,
    };
  });
}

export type EnrichedSuggestion = {
  getPosition: () => { left: string; top: string };
  offset: number;
  suggestion: Array<AH$Suggestion>;
};

/*
  Формирует автокомплит по контексту
*/
function getSuggestionsByContext(
  editorState: EditorState,
  field: Map<string, any>,
  isV2 = false
): EnrichedSuggestion | null {
  const selection = editorState.getSelection();
  const caretOffset = selection.getStartOffset();
  const enrichedEntitiesData = enrichEntitiesData(editorState);
  const firstBeforeCaret = findLast(enrichedEntitiesData, ({ offset }) => caretOffset > offset);
  const firstAfterCursor = find(enrichedEntitiesData, { key: get(firstBeforeCaret, 'key', 0) + 1 });
  const secondBeforeCursor = find(enrichedEntitiesData, {
    key: get(firstBeforeCaret, 'key', 0) - 1,
  });

  if (get(firstBeforeCaret, 'type') === 'SPACE' && get(firstAfterCursor, 'type') !== 'OPERATOR') {
    const offset = get(firstBeforeCaret, 'offset', 0) + get(firstBeforeCaret, 'length', 0);
    const getPosition = (entityKey: string) => () => {
      const { bottom: top, left } = (
        (document.getElementById(entityKey) || {}) as HTMLElement
      ).getBoundingClientRect();

      return {
        top: `${top}px`,
        left: `${left}px`,
      };
    };
    const operatorsSuggestion = [
      ...operators.map((operator) => ({ ...operator, type: 'OPERATOR' })),
    ];
    const filteredOperatorsSuggestion = (() => {
      if (isV2) {
        return [];
      }
      if (field.get('type') === 'locationRange' && !isV2) {
        return [];
      }
      if (field.get('suggest') === 'location') {
        return reject(operatorsSuggestion, { id: 'AND' });
      }
      if (field.get('suggest') === 'folder') {
        return filter(operatorsSuggestion, { id: 'OR' });
      }
      return operatorsSuggestion;
    })() as Array<AH$Suggestion>;
    const parensAndQuotesSuggestion: Array<AH$Suggestion> = isV2
      ? []
      : [
          { id: '( )', name: '( )', type: 'PAREN' },
          { id: '" "', name: '" "', type: 'TERM' },
        ];

    return {
      offset,
      getPosition: getPosition(get(firstBeforeCaret, 'entityKey', '')),
      suggestion:
        get(secondBeforeCursor, 'type') !== 'OPERATOR'
          ? filteredOperatorsSuggestion
          : parensAndQuotesSuggestion,
    };
  }

  return null;
}

/*
  Добавляет item в конец массива («группы») последнего элемента массива «групп» array
*/
const pushInLastGroup = (array: Array<any>, item: any): Array<any> => [
  ...dropRight(array),
  [...(last(array) || []), item],
];

/*
  Добавляет в конец массива «групп» новый пустой массив («группу»),
  если такой пустой «группы» в массиве «групп» ещё нет.
*/
const pushNewGroupIfNeed = (array: Array<Array<any>>): Array<Array<any>> => [...array, []];

/*
  Формирует группы терминов
*/
const getGroups = (
  enrichedEntitiesData: Array<EnrichedEntity>,
  includeApplied = false
): Array<Array<EnrichedEntity>> =>
  enrichedEntitiesData.reduce((acc: Array<any>, { type, ...enrichedEntityData }) => {
    switch (type) {
      case 'TERM':
        return pushInLastGroup(acc, { type, ...enrichedEntityData });
      case 'APPLIED_TERM':
        return includeApplied
          ? pushInLastGroup(acc, { type, ...enrichedEntityData })
          : pushNewGroupIfNeed(acc);
      case 'SPACE':
        return includeApplied ? pushNewGroupIfNeed(acc) : acc;
      default:
        return pushNewGroupIfNeed(acc);
    }
  }, []);

/*
    Пример.
    groups = ['term1', 'term2', 'term3']
    return [['term3'], ['term2', 'term3'], ['term1', 'term2', 'term3']]
  */
const getMatchs = (
  groups: Array<string | EnrichedEntity>
): Array<Array<string | EnrichedEntity>> => [
  ...groups.reduce(
    (acc: Array<Array<string | EnrichedEntity>>, item) => [...acc, [...(last(acc) || []), item]],
    []
  ),
  ...(isEmpty(groups) ? [] : getMatchs(tail(groups))),
];

/*
    Фильтрует массив массивов по максимально допустимой длнне вложенных массивов
*/
const filterByMaxTerms =
  (maxLength: number) =>
  (groups: Array<Array<any>>): Array<Array<any>> =>
    groups.map((group) => group.filter(({ length }) => length <= maxLength));

interface Collocation {
  length: number;
  offset: number;
  startEntityKey: string;
  termsCount: number;
  text: string;
  whithSynonym: boolean;
}

/*
  Пример.
  groups = [
    [{ text: 'term1', offset: 0, entityKey: 111 }, { text: 'term2', offset: 6, entityKey: 222 }]
  ]
  return [
    { text: 'term1 term2', termsCount: 2, offset: 0, startEntityKey: 111, length: 11 }
  ]
*/
const joinToCollocations = (groups: Array<Array<EnrichedEntity>>): Array<Collocation> =>
  groups.map((terms) => {
    const text = map(terms, 'text').join(' ');
    const { length } = text;
    const termsCount = terms.length;
    const { offset, entityKey: startEntityKey, entity } = first(terms) as EnrichedEntity;
    const { whithSynonym } = entity.getData();

    return { text, termsCount, offset, startEntityKey, length, whithSynonym };
  });

/*
  Возвращает объект с данными для автокомплита
  для максимально сложного (содержащего большее кол-во терминов) словосочитания.
*/
const getSuggestionsByCollocation = (
  suggestions: Map<string, Array<AH$Suggestion>>,
  [firstCollocation, ...restCollocation]: Array<Collocation>,
  getSearchByMessage: (term: string) => ReactElement,
  exactSearchable = false,
  flat = false,
  isV2 = false
): EnrichedSuggestion | null => {
  if (!isEmpty(firstCollocation)) {
    const { offset, startEntityKey, text } = firstCollocation;
    const normalizedText = normalizeSuggestionKey(text);
    const isSuggestionFetched = !isNil(suggestions.get(normalizedText));
    const v2Suggestion = {
      id: '',
      name: getSearchByMessage(trim(text, '"')) as unknown as string,
      suggestedWord: text,
      synonyms: [],
      variants: [],
      isExact: true,
      isMainSkill: false,
    };

    const getPosition = (id: string) => () => {
      const { bottom: top, left } = (
        (document.getElementById(id) || {}) as HTMLElement
      ).getBoundingClientRect();

      return {
        top: `${top}px`,
        left: `${left}px`,
      };
    };

    if (isSuggestionFetched) {
      let suggestion = suggestions.get(normalizedText, []);
      const knownReachSuggestion = find(
        suggestion,
        ({ isKnown, synonyms, variants }) => isKnown && !(isEmpty(synonyms) && isEmpty(variants))
      );

      if (isV2) {
        suggestion = [v2Suggestion, ...suggestion];
      } else if (knownReachSuggestion && exactSearchable) {
        suggestion = [
          ...suggestion,
          {
            id: text,
            name: trim(text, '"'),
            suggestedWord: text,
            synonyms: [],
            variants: [],
            isExact: true,
          },
        ];
      }

      if (!isEmpty(suggestion)) {
        return {
          suggestion,
          offset,
          getPosition: getPosition(startEntityKey),
        };
      }
      if (!isEmpty(restCollocation) && !flat) {
        return getSuggestionsByCollocation(
          suggestions,
          restCollocation,
          getSearchByMessage,
          exactSearchable
        );
      }
    } else if (isV2) {
      return {
        suggestion: [v2Suggestion],
        offset,
        getPosition: getPosition(startEntityKey),
      };
    }
  }
  return null;
};

/*
  Удаляет из текста повторяющиеся пробелы и пробелы по краям
*/
const trimQueryText = (text: string): string => text.trim().replace(/\s+/g, ' ');

interface Token {
  end: number;
  start: number;
  text: string;
  type: AH$FormEntityType;
  whithSynonym: boolean;
}

/*
  Заменяет *-маску на соответствующий текст.
*/
const replaceMarks = (
  tokens: Array<Token>,
  marksByOffset: { [key: number]: string }
): Array<Token> =>
  tokens.map((token) =>
    marksByOffset[token.start]
      ? { ...token, text: marksByOffset[token.start] }
      : { ...token, whithSynonym: true }
  );

interface EnrichedToken {
  end: number;
  field: Map<string, any>;
  start: number;
  text: string;
  type: AH$FormEntityType;
  isExcluded?: boolean;
  isMainSkill?: boolean;
  skillRange?: number;
  whithSynonym?: boolean;
}

/*
  Формирует токены объдинённого запроса.
*/
const getUnitedQuery =
  (isV2 = false) =>
  (
    queries: List<{ excluded: boolean; tokens: Array<EnrichedToken> }>
  ): Array<EnrichedToken | { key: string; length: number; text: string }> => {
    let tokens = (
      queries as unknown as Array<{
        excluded: boolean;
        tokens: Array<EnrichedToken>;
      }>
    ).reduce(
      (
        unitedTokens: Array<EnrichedToken | { key: string; length: number; text: string }>,
        { tokens, excluded }
      ) => {
        const TOKENS = {
          PAREN_LEFT: { key: 'PAREN', text: '(', length: 1 },
          PAREN_RIGHT: { key: 'PAREN', text: ')', length: 1 },
          SPACE: { key: 'SPACE', text: ' ', length: 1 },
          AND: { key: 'OPERATOR', text: 'AND', length: 3 },
          NOT: { key: 'OPERATOR', text: 'NOT', length: 3 },
        };

        if (tokens.length > 0) {
          let group: Array<EnrichedToken | $Values<typeof TOKENS>> = tokens.map((token) => ({
            ...token,
            isExcluded: (isV2 && excluded) || token.isExcluded,
            skillRange: token.skillRange || 0,
          }));

          if (get(queries, 'size') > 1 && tokens.length > 2) {
            group = isV2 ? [...group] : [TOKENS.PAREN_LEFT, ...group, TOKENS.PAREN_RIGHT];
          }

          if (excluded) {
            group = isV2 ? [...group] : [TOKENS.NOT, TOKENS.SPACE, ...group];
          }

          if (unitedTokens.length) {
            group = isV2
              ? [TOKENS.SPACE, ...group]
              : [TOKENS.SPACE, TOKENS.AND, TOKENS.SPACE, ...group];
          }

          return [...unitedTokens, ...group];
        }
        return unitedTokens;
      },
      []
    );

    let end = 0;

    tokens = tokens.map((token) => {
      const start = end;

      end += token.text.length;
      return { ...token, start, end };
    });

    return tokens;
  };

/*
  Формирует токены на основе editorState.
*/
export const getTokensByEditorState = (
  editorState: EditorState,
  field: Map<string, any>
): Array<EnrichedToken> =>
  enrichEntitiesData(editorState).map(({ type, offset, length, text }) => ({
    type,
    text,
    start: offset,
    end: offset + length,
    field,
  }));

/*
  Формирует все возможные словосочитания в запросе
*/
export const getCollocations = (
  editorState: EditorState,
  callbackFilter = (groups: Array<Array<EnrichedEntity>>): Array<Array<EnrichedEntity>> => groups,
  includeApplied = false,
  isV2 = false
): Array<Collocation> =>
  joinToCollocations(
    callbackFilter(
      flatten(
        filterByMaxTerms(isV2 ? Infinity : 4)(
          getGroups(enrichEntitiesData(editorState), includeApplied).map(getMatchs)
        )
      )
    )
  );

/*
  Возвращает объект с данными для автокомплита в зависимость от положения каретки.
*/
export const getSuggestions = (
  field: Map<string, any>,
  editorState: EditorState,
  getSearchByMessage: (term: string) => ReactElement,
  allSuggestions = Map<string, Array<AH$Suggestion>>(),
  isV2 = false
): EnrichedSuggestion | null => {
  if (editorState.getSelection().isCollapsed()) {
    const enrichedEntities = enrichEntitiesData(editorState);
    const isLocationRange = field.get('type') === 'locationRange';
    // Для locationRange показывает синонимы не только когда каретка в конце
    const havingCaretOnEnd = isV2
      ? findLast(enrichedEntities, { type: 'TERM' })
      : find(enrichedEntities, { hasCaretOnEnd: true }) || (isLocationRange && enrichedEntities[0]);

    if (havingCaretOnEnd) {
      const exactSearchable = field.get('exactSearchable');
      const { offset } = havingCaretOnEnd;
      const callbackFilter = (offset: number) => (groups: Array<Array<EnrichedEntity>>) =>
        groups.filter((group) => get(last(group), 'offset') === offset);
      const collocations = getCollocations(editorState, callbackFilter(offset), undefined, isV2);

      return (
        getSuggestionsByCollocation(
          allSuggestions,
          collocations,
          getSearchByMessage,
          exactSearchable,
          isLocationRange || isV2,
          isV2
        ) || getSuggestionsByContext(editorState, field, isV2)
      );
    }
  }
  return null;
};

/*
  Вставляет текст из автокомплита.
*/
export const getEditorStateWhithApplyedEntity = ({
  queryKey,
  field,
  editorState,
  selection,
  text,
  type,
  whithSynonym,
  suggestionId,
  isMainSkill,
  isV2 = false,
}: {
  editorState: EditorState;
  field: Map<string, any>;
  queryKey: number;
  selection: SelectionState;
  text: string;
  type: AH$FormEntityType;
  isMainSkill?: boolean;
  isV2?: boolean;
  suggestionId?: string | null;
  whithSynonym?: boolean | null;
}): EditorState => {
  const enrichedEntities = enrichEntitiesData(editorState);
  let newEditorState = editorState;
  let currentContent = newEditorState.getCurrentContent();
  const isAppliedTerm = isV2 && type === 'TERM';
  const entity = currentContent.createEntity(
    isAppliedTerm ? 'APPLIED_TERM' : type,
    isAppliedTerm ? 'IMMUTABLE' : 'MUTABLE',
    {
      queryKey,
      field,
      text,
      whithSynonym,
      suggestionId,
      editorState,
      isMainSkill,
    }
  );
  const entityKey = entity.getLastCreatedEntityKey();
  const applyedSuggestionEndOffset = selection.getAnchorOffset() + text.length;
  const endEntity =
    enrichedEntities.find(({ offset, length }) => offset + length >= selection.getFocusOffset()) ||
    ({} as EnrichedEntity);

  currentContent = Modifier.replaceText(
    currentContent,
    selection.merge({
      focusOffset: get(endEntity, 'offset') + get(endEntity, 'length'),
    }) as SelectionState,
    text
  );
  currentContent = Modifier.applyEntity(
    currentContent,
    selection.merge({ focusOffset: applyedSuggestionEndOffset }) as SelectionState,
    entityKey
  );

  newEditorState = EditorState.set(newEditorState, { currentContent });
  newEditorState = EditorState.forceSelection(
    newEditorState,
    selection.merge({
      anchorOffset: applyedSuggestionEndOffset,
      focusOffset: applyedSuggestionEndOffset,
    }) as SelectionState
  );
  return newEditorState;
};

/*
  Создаёт мета-данные (entities) по токенизированному тексту.
*/
export const getEditorStateWithNewEntities = ({
  queryKey,
  editorState,
  tokens,
  prevEditorState,
  force = false,
  isV2 = false,
  isReadOnly = false,
}: {
  editorState: EditorState;
  queryKey: number | null;
  tokens: Array<EnrichedToken>;
  force?: boolean;
  isReadOnly?: boolean;
  isV2?: boolean;
  prevEditorState?: EditorState;
}): EditorState => {
  const prevEnrichedEntitiesData = (prevEditorState && enrichEntitiesData(prevEditorState)) || [];

  return tokens.reduce((editorState, token: EnrichedToken) => {
    const enrichedEntitiesData = enrichEntitiesData(editorState);
    const currentSelection = editorState.getSelection();
    let currentContent = editorState.getCurrentContent();
    const enrichedEntityData = find(
      enrichedEntitiesData.filter(
        ({ type }) =>
          isEqual(['AI', 'TERM'], [type, token.type]) ||
          isEqual(['APPLIED_TERM', 'TERM'], [type, token.type]) ||
          type === token.type
      ),
      {
        offset: token.start,
        length: token.end - token.start,
      }
    );
    const prevData =
      enrichedEntityData &&
      find(prevEnrichedEntitiesData, pick(enrichedEntityData, ['entityKey', 'length']));
    const isEntityMutated = !prevData;
    const isAiTerm = get(enrichedEntityData, 'type') === 'AI';
    const hasFocus = currentSelection.getHasFocus() && currentSelection.isCollapsed();
    const shouldTermBeApplied = isV2 && token.type === 'TERM' && !hasFocus;
    const isAppliedTerm =
      (isV2 && prevData?.type === 'APPLIED_TERM') ||
      enrichedEntityData?.type === 'APPLIED_TERM' ||
      shouldTermBeApplied;

    if (!enrichedEntityData || (isEntityMutated && !isAiTerm) || shouldTermBeApplied || force) {
      const selection = currentSelection.merge({
        anchorOffset: token.start,
        focusOffset: token.end,
      });
      const data = enrichedEntityData?.entity.getData();
      const text = data?.text || token.text;
      const currentText = text.startsWith('-') ? text.slice(1) : text;

      /* обогащение данными сущностей */
      const entityKey = currentContent
        .createEntity(
          isAppliedTerm ? 'APPLIED_TERM' : token.type,
          isAppliedTerm ? 'IMMUTABLE' : 'MUTABLE',
          {
            queryKey,
            editorState,
            field: token.field,
            text: currentText,
            whithSynonym: token.whithSynonym,
            isExcluded: enrichedEntityData?.isExcluded || token.isExcluded,
            suggestionId: enrichedEntityData?.suggestionId,
            isReadOnly,
            isMainSkill: !!enrichedEntityData?.isMainSkill,
            skillRange: enrichedEntityData?.skillRange || token.skillRange || 0,
            isKnown: data?.isKnown,
          }
        )
        .getLastCreatedEntityKey();

      currentContent = Modifier.applyEntity(currentContent, selection as SelectionState, entityKey);
      currentContent = currentContent.set(
        'selectionBefore',
        editorState.getCurrentContent().getSelectionBefore()
      ) as ContentState;
      currentContent = currentContent.set(
        'selectionAfter',
        editorState.getCurrentContent().getSelectionAfter()
      ) as ContentState;

      return EditorState.set(editorState, {
        currentContent,
      });
    }
    return editorState;
  }, editorState);
};

/*
  Формирует данные по запросу на основ URL.
  В том числе делает *-маску для точного совпадения.
*/
export const compileFromUrlFormat = (
  location: AH$RouterLocation | Location | string = window.location,
  aiQueries?: AH$MetaQueries,
  isV2 = false
): Config => {
  const {
    profilesQuery: { q = [] },
  } = createLocation(location);
  const minusRegex = /(^|,)\-/;

  return flatten([q])
    .map((param) => {
      const marksByOffset: Record<number, string> = {};
      const [, flag, , field, , text = ''] =
        /^(-)?(boolean)?([a-zA-Z]+)\[(\d+)\]:(.+)$/.exec(param) || [];
      const trimmedText = trimQueryText(text);

      // Логика для замены знака исключения -
      const excludedTermsOffsets = [];
      let transformedText = trimmedText;

      while (isV2 && (transformedText.startsWith('-') || transformedText.includes(',-') || false)) {
        const match = transformedText.match(minusRegex);

        if (!match || !isNumber(match.index)) break;

        const isStart = !match[0].startsWith(',');

        excludedTermsOffsets.push(isStart ? match.index : match.index + 1);
        transformedText = transformedText.replace(minusRegex, isStart ? '' : ',');
      }

      transformedText = isV2 ? transformedText.replaceAll(',', ' ') : transformedText;

      const markedText = transformedText.replace(
        /(^|\s)<(.*?)>($|\s)/g,
        (str, startSpace, trimmedText, endSpace, offset) => {
          const leftShiftOffset = '<>'.length * Object.values(marksByOffset).length;

          marksByOffset[offset + startSpace.length - leftShiftOffset] = trimmedText;

          return startSpace + Array.from(trimmedText).fill('*').join('') + endSpace;
        }
      );
      const aiQuery =
        aiQueries && flatten(Object.values(aiQueries)).find(({ keyword }) => keyword === text);

      return {
        excluded: flag === '-',
        field: camelCase(aiQuery ? 'metaqueries' : field),
        marksByOffset,
        text: markedText,
        excludedTermsOffsets,
      };
    })
    .filter(({ text }) => !isEmpty(text));
};

/*
  Специальная логика для поиска по папке, так как саджест должен быть
  в первую очередь завязан на id
*/
const getFolderFieldSuggestionMap = (
  entities: Array<EnrichedEntity>,
  suggestions: SearchFormState['suggestions'],
  suggestionKey: string
): Record<string, AH$Suggestion | undefined> | null => {
  const folderField = compileFromUrlFormat().find(({ field }) => field === 'folder');

  if (!folderField) {
    return null;
  }

  const folderMatch = folderField.text.match(/id-\d+/g);

  if (!folderMatch) {
    return null;
  }

  return entities
    .filter(({ type }) => type === 'TERM' || type === 'APPLIED_TERM')
    .reduce((acc, { text }, index) => {
      const normalizedText = normalizeSuggestionKey(text);
      const fieldSuggestions = (suggestions.getIn([suggestionKey, normalizedText]) ||
        []) as Array<AH$Suggestion>;
      const suggestion =
        fieldSuggestions.find(({ id }) => id === folderMatch[index]) || first(fieldSuggestions);

      return { ...acc, [normalizedText]: suggestion };
    }, {});
};

/*
  Формирует данные дня генерации URL
*/
export const transformToUrl = (
  queries: SearchFormState['queries'],
  suggestions: SearchFormState['suggestions'],
  isV2 = false
): AH$SearchQueryParamQ =>
  (queries as unknown as Array<Query>).reduce(
    (
      acc: Array<{ excluded: boolean; field: string; rawText: string }>,
      { editorState, field, excluded }
    ) => {
      const enrichedEntityData = enrichEntitiesData(editorState);
      const fieldSuggest = field.get('suggest') || '';
      const isFolder = field.get('type') === 'folder';
      const suggestionMap = isFolder
        ? getFolderFieldSuggestionMap(enrichedEntityData, suggestions, fieldSuggest)
        : null;
      const text = enrichedEntityData.reduce(
        (acc, { type, entity, text, isExcluded, skillRange: skillRangeData }) => {
          const {
            whithSynonym,
            suggestionId,
            skillRange: skillRangeEntity,
          } = (entity as EntityInstance).getData();
          const skillRange = skillRangeData || skillRangeEntity || 0;

          if (!['TERM', 'AI', 'APPLIED_TERM'].includes(type)) {
            if (type === 'SPACE' && isV2) {
              return acc;
            }
            return acc.concat(text);
          }

          const normalizedText = normalizeSuggestionKey(text);
          const suggestion =
            (suggestionMap && suggestionMap[normalizedText]) ||
            first(suggestions.getIn([fieldSuggest, normalizedText]) || []);

          const shouldReplace = isV2
            ? get(suggestion, 'name', '').toLowerCase() === normalizedText
            : get(suggestion, 'isKnown');
          const rawTextToUrl =
            (suggestionId && quoteCollocation(suggestionId)) ||
            (shouldReplace ? quoteCollocation(get(suggestion, 'id', text)) : text);
          const isLocationRange =
            field.get('type') === 'locationRange' || (field.get('type') === 'location' && isV2);
          const locationRange = field.get('locationRange');
          const isSkillAll = field.get('type') === 'skillAll';
          const textToAppend = whithSynonym || isV2 ? rawTextToUrl : `<${text}>`;
          const isExcludedTerm = type === 'APPLIED_TERM' && isExcluded;
          const normalizedTextToAppend = isExcludedTerm
            ? `${isV2 ? '-' : 'NOT '}${textToAppend}`
            : textToAppend;
          const textToUrl = acc.concat(
            isV2 && acc.length ? `,${normalizedTextToAppend}` : normalizedTextToAppend
          );

          if (isLocationRange && locationRange) {
            return `${textToUrl}+${locationRange}`;
          }

          if (isSkillAll && skillRange) {
            return `${textToUrl}+${skillRange}`;
          }
          return textToUrl;
        },
        ''
      );

      const rawText = trimQueryText(text);
      const initFieldType = field.get('type') || '';
      const fieldType = initFieldType === 'metaqueries' ? 'text' : initFieldType;

      if (!isEmpty(rawText)) {
        return [...acc, { rawText, field: fieldType, excluded, notBoolean: isV2 }];
      }
      return acc;
    },
    []
  );

type ConfigItem = {
  field: string;
  text: string;
  excluded?: boolean;
  excludedTermsOffsets?: number[];
  marksByOffset?: { [key: number]: string };
};

export type Config = Array<ConfigItem>;

/*
  Токенизирует текст.
*/
export const getTokens = ({
  field,
  text,
  isV2 = false,
  marksByOffset = {},
  excludedTermsOffsets,
}: {
  field: Map<string, any>;
  text: string;
  excludedTermsOffsets?: number[];
  isV2?: boolean;
  marksByOffset?: Record<string, any>;
}): Array<EnrichedToken> =>
  flow(
    tokenize,
    (tokens: Array<Token>) => replaceMarks(tokens, marksByOffset),
    (tokens) =>
      tokens.map((token, index) => {
        const isFieldSkillAll = field.get('type') === 'skillAll';
        const { text: skillAllText, skillRange: skillAllSkillRange } = extractSkillRange(
          token.text
        );
        const currentText = isFieldSkillAll ? skillAllText : token.text;
        const text = currentText.startsWith('-') ? currentText.slice(1) : currentText;

        return {
          ...token,
          text,
          type: isV2 && token.type === 'OPERATOR' ? 'TERM' : token.type,
          field,
          isExcluded: excludedTermsOffsets?.includes(token.start),
          skillRange: skillAllSkillRange[index],
        };
      })
  )(text);

/*
  Формирует EditorState объединённого запроса.
*/
export const getUnitedEditorState: (queries: List<Query>) => EditorState = flow(
  (queries: Array<Query>) =>
    queries.map(({ editorState, excluded, field }) => ({
      excluded,
      tokens: getTokensByEditorState(editorState, field),
    })),
  getUnitedQuery(),
  (tokens) =>
    getEditorStateWithNewEntities({
      queryKey: null,
      editorState: EditorState.createWithContent(
        ContentState.createFromText(map(tokens, 'text').join('')),
        Decorator
      ),
      tokens,
    })
);

export const setBlockData = (
  editorState: EditorState,
  data: { focused: boolean; suggestions: SearchFormState['suggestions'] }
): EditorState => {
  const currentContent = editorState.getCurrentContent();
  const dataPath = ['blockMap', get(currentContent.getFirstBlock(), 'key'), 'data'];

  return EditorState.set(editorState, {
    currentContent: currentContent.mergeIn(dataPath, data),
  });
};

export const convertFromUrl = (
  location: AH$RouterLocation | Partial<Location> = window.location,
  rangeMeasure?: 'km' | 'mi',
  decodeQuery = true,
  aiQueries?: AH$MetaQueries,
  isV2 = false,
  isReadOnly = false
): EditorState =>
  flow(
    () => compileFromUrlFormat(location as AH$RouterLocation, aiQueries, isV2),
    (queries: Config) =>
      queries.reduce((acc: Config, query: ConfigItem) => {
        const text = isV2
          ? trimQueryText(query.text).replaceAll('(', '').replaceAll(' )', '').replaceAll(')', '')
          : trimQueryText(query.text);
        const transformLocationRangeText = isV2
          ? trimQueryText(query.text)
              .replaceAll(/\s[0-9]{2,3}/g, '')
              .replaceAll('(', '')
              .replaceAll(' )', '')
              .replaceAll(') ', '')
              .replaceAll(/\+(\d+$)/g, '') // Убирает +40 из locationRange в истории / сохраненных запросах
          : trimQueryText(query.text).replaceAll(/\s[0-9]{2,3}/g, '');

        if (text) {
          return [
            ...acc,
            {
              ...query,
              text:
                (query.field === 'locationRange' ||
                  query.field === 'location' ||
                  query.field === 'skillAll') &&
                isV2
                  ? transformLocationRangeText
                  : text,
            },
          ];
        }
        return acc;
      }, []),
    (queries: Config) =>
      queries.map((query) => {
        const isLocationRange =
          query.field === 'locationRange' || (query.field === 'location' && isV2);
        const decodedText = decodeQuery ? decodeURIComponent(query.text) : query.text;

        const range = (decodedText.match(/\d+/) || [])[0];

        const text =
          isLocationRange && rangeMeasure === 'mi' && range
            ? decodedText.replace(
                /\d+./,
                String(KM_TO_MI[parseInt(range, 10) as keyof typeof KM_TO_MI])
              )
            : decodedText;

        return {
          ...query,
          tokens: getTokens({
            field: Map(FIELDS.get(query.field) as Record<string, any>),
            text,
            isV2,
            marksByOffset: query.marksByOffset,
            excludedTermsOffsets: query.excludedTermsOffsets,
          }),
        };
      }),
    getUnitedQuery(isV2),
    (tokens: Array<EnrichedToken>) =>
      getEditorStateWithNewEntities({
        queryKey: null,
        editorState: EditorState.createWithContent(
          ContentState.createFromText(map(tokens, 'text').join('')),
          Decorator
        ),
        tokens,
        isV2,
        isReadOnly,
      })
  )(location);

export const getQueriesFromUrl = (
  config?: Config,
  aiQueries?: AH$MetaQueries,
  isV2 = false
): SearchFormState['queries'] =>
  (config || compileFromUrlFormat(undefined, aiQueries, isV2)).reduce(
    (
      queries,
      { field: fieldKey, excluded, text: rawText, marksByOffset, excludedTermsOffsets },
      queryKey
    ) => {
      const { text, locationRange } = extractLocationRange(rawText, fieldKey);
      const field = Map({
        ...(FIELDS.get(fieldKey) as Record<string, any>),
        notBoolean: isV2,
        locationRange,
      });

      const tokens = getTokens({
        field,
        text,
        isV2,
        marksByOffset,
        excludedTermsOffsets,
      });
      const textWithoutMarks = reduce(
        marksByOffset,
        (acc, value, offset) =>
          `${acc.substr(0, +offset)}${value}${acc.substr(toInteger(offset) + value.length)}`,
        text
      );
      const editorState = getEditorStateWithNewEntities({
        queryKey,
        editorState: EditorState.createWithContent(
          ContentState.createFromText(textWithoutMarks),
          Decorator
        ),
        tokens,
        isV2,
      });

      return queries.push(makeQuery({ field, excluded, editorState }) as unknown as Query);
    },
    List<Query>()
  );

export const markAiTerms = (
  editorState: EditorState,
  aiQueries: AH$MetaQueries,
  language: 'en' | 'ru',
  isV2 = false
): EditorState =>
  reverse(enrichEntitiesData(editorState)).reduce((editorState, enrichedEntityData) => {
    const aiQuery = flatten(Object.values(aiQueries)).find(
      ({ keyword }) => keyword === enrichedEntityData.text
    );

    if (aiQuery) {
      const selection = editorState.getSelection().merge({
        anchorOffset: enrichedEntityData.offset,
        focusOffset: enrichedEntityData.offset + enrichedEntityData.length,
      });
      const quotedText = quoteCollocation(aiQuery[language === 'ru' ? 'name_ru' : 'name_en']);
      const replacedEndOffset = enrichedEntityData.offset + quotedText.length;
      const currentContent = (() => {
        const content = Modifier.replaceText(
          editorState.getCurrentContent(),
          selection as SelectionState,
          quotedText
        );

        const entity = content.createEntity(
          isV2 ? 'APPLIED_TERM' : 'AI',
          isV2 ? 'IMMUTABLE' : 'MUTABLE',
          {
            text: quotedText,
            field: enrichedEntityData.field,
            whithSynonym: true,
            suggestionId: aiQuery.keyword,
          }
        );
        const entityKey = entity.getLastCreatedEntityKey();

        return Modifier.applyEntity(
          content,
          selection.merge({ focusOffset: replacedEndOffset }) as SelectionState,
          entityKey
        );
      })();

      Entity.mergeData(enrichedEntityData.entityKey, { text: enrichedEntityData.text });

      return EditorState.set(editorState, { currentContent });
    }
    return editorState;
  }, editorState);

export const getTermsForSuggests = (
  queries: SearchFormState['queries'],
  suggestions: SearchFormState['suggestions'],
  isV2 = false
): AH$Type2term =>
  mapValues(
    omitBy(
      (queries as unknown as Array<Query>).reduce((acc, { editorState, field }: Query) => {
        const hasInSuggests = (text: string) =>
          (suggestions.get(field.get('suggest')) || new (Map as any)()).has(
            normalizeSuggestionKey(text)
          );
        const collocations = getCollocations(editorState, undefined, isV2, isV2);
        const newTerms = field.get('suggest')
          ? collocations
              .filter(({ text }) => !hasInSuggests(text))
              .map(({ text, whithSynonym }) => ({
                text: normalizeSuggestionKey(text),
                isExact: !whithSynonym,
              }))
          : [];

        return merge(acc, {
          [field.get('suggest') || '']: newTerms,
        });
      }, {}),
      isEmpty
    ),
    uniq
  );

export const getCurrentTextSuggestion = (
  suggestions: SearchFormState['suggestions'],
  text: string,
  suggestType: string
): AH$Suggestion => {
  const normalizedText = normalizeSuggestionKey(text);
  const fieldSuggestions = suggestions.getIn([suggestType, normalizedText]);
  const suggestion =
    (head(fieldSuggestions) as AH$Suggestion) ||
    // поиск для случая, кода нет suggestions непосредственно по ключу со значением normalizedText
    (suggestions as any)
      .get(suggestType, Map())
      .toList()
      .flatMap((val: AH$Suggestion) => val)
      .find(({ id, name }: { id: string; name: string }) =>
        [id, name].map(normalizeSuggestionKey).includes(normalizedText)
      );

  return suggestion;
};

export const moveCaretFocusToEnd = (editorState: EditorState): EditorState =>
  EditorState.moveFocusToEnd(EditorState.moveSelectionToEnd(editorState));

export const handlePastedText = (
  text: string,
  editorState: EditorState,
  data: { field?: Map<string, any>; suggestions?: EnrichedSuggestion }
): EditorState => {
  const getText = (currentText: string) => {
    if (data.field?.get('type') === 'skillAll') {
      const decodedText = decodeURIComponent(currentText);
      const matchedText = decodedText.match(/(.*)\+(\d+$)/);

      return {
        text: matchedText
          ? quoteCollocation(
              matchedText[1].startsWith('-') ? matchedText[1].slice(1) : matchedText[1]
            )
          : quoteCollocation(currentText.startsWith('-') ? currentText.slice(1) : currentText),
        skillRange: matchedText ? parseInt(matchedText[2], 10) : 0,
      };
    }

    return {
      text: quoteCollocation(currentText.startsWith('-') ? currentText.slice(1) : currentText),
      skillRange: 0,
    };
  };
  const ENTITY_DELIMITER = /\s*[,\t;\r\n]+\s*/g;

  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();

  // Разделяем текст на части, используя разделитель
  const parts = text.split(ENTITY_DELIMITER);

  let newContentState = contentState;
  let currentSelection = selectionState;

  /* TODO: Добавить suggestions в эту функцию из компонента QueryEditor. А в этот компонент как то прокинуть их */
  parts.forEach((part) => {
    const trimmedPart = part.trim();
    // Создаем сущность для каждой части текста
    const quotedText = getText(trimmedPart);
    const contentWithEntity = newContentState.createEntity('APPLIED_TERM', 'IMMUTABLE', {
      text: quotedText.text,
      field: data.field,
      whithSynonym: true,
      isExcluded: trimmedPart.startsWith('-'),
      isMainSkill:
        data.suggestions?.suggestion?.find(
          (suggestion) => suggestion.name.toLowerCase() === trimmedPart.toLowerCase()
        )?.isMainSkill ||
        !!quotedText.skillRange ||
        false,
      skillRange: quotedText.skillRange,
      isKnown:
        data.suggestions?.suggestion?.find(
          (suggestion) => suggestion.name.toLowerCase() === trimmedPart.toLowerCase()
        )?.isKnown || false,
    });
    const entityKey = contentWithEntity.getLastCreatedEntityKey();

    // Вставляем текст с сущностью в редактор
    newContentState = Modifier.replaceText(
      newContentState,
      currentSelection,
      quotedText.text,
      undefined,
      entityKey
    );

    // Обновляем текущее состояние выделения
    const blockKey = currentSelection.getStartKey();
    const blockLength = newContentState.getBlockForKey(blockKey).getLength();

    currentSelection = currentSelection.merge({
      anchorOffset: blockLength,
      focusOffset: blockLength,
    }) as SelectionState;

    // Добавляем пробел между сущностями, если это не последняя часть

    newContentState = Modifier.insertText(newContentState, currentSelection, ' ');
    const blockLengthWithSpace = newContentState.getBlockForKey(blockKey).getLength();

    currentSelection = currentSelection.merge({
      anchorOffset: blockLengthWithSpace,
      focusOffset: blockLengthWithSpace,
    }) as SelectionState;
  });

  return EditorState.push(editorState, newContentState, 'insert-characters');
};
