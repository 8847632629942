import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { noop, uniqBy } from 'lodash';

import { FETCHING_STATUSES } from 'constants/globals';
import abortable, { AbortablePromise, tryAbortPromise } from 'lib/abortablePromise';
import * as apiV6 from 'lib/apiV6';
import { getErrorHandler } from 'slices/app';

const SLICE_NAME = 'telegramDialogs';

let fetchDialogs: null | AbortablePromise<{
  payload: { count: number; next: string; previous: string; results: AH$TelegramDialog[] };
}> = null;

let fetchMessages: null | AbortablePromise<{
  payload: {
    count: number;
    next: string | null;
    previous: string | null;
    results: AH$TelegramDialogMessage[];
  };
}> = null;

export type TelegramDialogsState = {
  dialogs: {
    count: number;
    fetchingStatus: $Values<typeof FETCHING_STATUSES>;
    next: string | null;
    offset: number;
    previous: string | null;
    results: AH$TelegramDialog[];
    sortByRecruiter: string;
    sortBySimCard: string;
  };
  messages: {
    count: number;
    fetchingStatus: $Values<typeof FETCHING_STATUSES>;
    fetchingStatusLoadMore: $Values<typeof FETCHING_STATUSES>;
    next: string | null;
    offset: number;
    previous: string | null;
    results: AH$TelegramDialogMessage[];
  };
  newCreatedDialog: {
    fetchingStatus: $Values<typeof FETCHING_STATUSES>;
    result: AH$TelegramDialog | null;
  };
  sendMessageFetchingStatus: $Values<typeof FETCHING_STATUSES>;
};

export const telegramDialogsInitialState: TelegramDialogsState = {
  newCreatedDialog: {
    fetchingStatus: 'SUCCESS',
    result: null,
  },
  dialogs: {
    count: 0,
    next: '',
    previous: '',
    results: [],
    offset: 0,
    fetchingStatus: 'SUCCESS',
    sortByRecruiter: '',
    sortBySimCard: '',
  },
  messages: {
    results: [],
    count: 0,
    offset: 0,
    next: null,
    previous: null,
    fetchingStatus: 'SUCCESS',
    fetchingStatusLoadMore: 'SUCCESS',
  },
  sendMessageFetchingStatus: 'SUCCESS',
};

export const getAllDialogs = createAsyncThunk(
  `${SLICE_NAME}/getAllDialogs`,
  async (
    {
      created_by_id,
      limit = 20,
      offset = 0,
      telegram_account_id,
      onSuccess = noop,
      onError = noop,
    }: {
      created_by_id?: number;
      limit?: number;
      offset?: number;
      onError?: (error: string) => void;
      onSuccess?: () => void;
      telegram_account_id?: number;
    },
    { rejectWithValue }
  ) => {
    try {
      if (fetchDialogs && fetchDialogs.abort) {
        fetchDialogs.abort();
      }

      fetchDialogs = abortable(
        apiV6.getTelegramDialogs({
          created_by_id,
          limit,
          offset,
          telegram_account_id,
        })
      );

      const { payload } = await fetchDialogs;

      onSuccess();

      return payload;
    } catch (e) {
      if (e !== 'ABORTED_PROMISE') {
        getErrorHandler({
          parseError: true,
          silent: true,
          getError: ({ error }: { error: string }) => {
            onError(error);
          },
        })(e);
        return rejectWithValue(e);
      }

      return undefined;
    }
  }
);

export const getTelegramDialogIdMessages = createAsyncThunk(
  `${SLICE_NAME}/getTelegramDialogIdMessages`,
  async (
    {
      id,
      limit = 5,
      offset = 0,
      isFirstRequest = false,
      onSuccess = noop,
    }: {
      id: string;
      isFirstRequest: boolean;
      limit?: number;
      offset?: number;
      onSuccess?: (results: AH$TelegramDialogMessage[]) => void;
    },
    { rejectWithValue }
  ) => {
    try {
      if (fetchMessages && fetchMessages.abort) {
        fetchMessages.abort();
      }
      fetchMessages = abortable(apiV6.getTelegramDialogIdMessages({ id, limit, offset }));
      const { payload } = await fetchMessages;

      onSuccess(payload.results);

      return { ...payload, results: payload.results.reverse(), isFirstRequest };
    } catch (e) {
      if (e !== 'ABORTED_PROMISE') {
        getErrorHandler()(e);
        return rejectWithValue(e);
      }

      return undefined;
    }
  }
);

export const createTelegramDialog = createAsyncThunk(
  `${SLICE_NAME}/createTelegramDialog`,
  async (
    {
      profile_id,
      username,
      onSuccess = noop,
      onError = noop,
    }: {
      profile_id: number;
      username: string;
      onError?: (error: string) => void;
      onSuccess?: (dialogId: number) => void;
    },
    { rejectWithValue }
  ) => {
    try {
      const { payload } = await apiV6.createTelegramDialog({ profile_id, username });

      onSuccess(payload.id);

      return payload;
    } catch (e) {
      getErrorHandler({
        parseError: true,
        silent: true,
        getError: ({ error }: { error: string }) => {
          onError(error);
        },
      })(e);

      return rejectWithValue(e);
    }
  }
);

export const sendTelegramMessage = createAsyncThunk(
  `${SLICE_NAME}/sendTelegramMessage`,
  async (
    {
      dialogId,
      message,
      onSuccess = noop,
    }: {
      dialogId: string;
      message: string;
      onSuccess?: () => void;
    },
    { rejectWithValue }
  ) => {
    try {
      const { payload } = await apiV6.sendTelegramMessage({ dialogId, message });

      onSuccess();

      return payload;
    } catch (e) {
      getErrorHandler()(e);

      return rejectWithValue(e);
    }
  }
);

const telegramDialogsSlice = createSlice({
  name: SLICE_NAME,
  initialState: telegramDialogsInitialState,
  reducers: {
    resetDialogs: (state) => {
      state.dialogs = telegramDialogsInitialState.dialogs;
      tryAbortPromise(fetchDialogs);
    },
    setOffsetDialogs: (state, action: PayloadAction<number>) => {
      state.dialogs.offset = action.payload;
    },
    setOffsetMessages: (state, action: PayloadAction<number>) => {
      state.messages.offset = action.payload;
    },
    resetNewCreatedDialog: (state) => {
      state.newCreatedDialog = { fetchingStatus: 'SUCCESS', result: null };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllDialogs.pending, (state) => {
      state.dialogs.fetchingStatus = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(getAllDialogs.fulfilled, (state, action) => {
      if (action.payload) {
        state.dialogs.fetchingStatus = FETCHING_STATUSES.SUCCESS;
        state.dialogs.next = action.payload.next;
        state.dialogs.previous = action.payload.previous;
        state.dialogs.count = action.payload?.count;
        state.dialogs.results = uniqBy([...state.dialogs.results, ...action.payload.results], 'id');
      }
    });
    builder.addCase(getAllDialogs.rejected, (state) => {
      state.dialogs.fetchingStatus = FETCHING_STATUSES.ERROR;
    });
    builder.addCase(getTelegramDialogIdMessages.pending, (state, action) => {
      state.messages.fetchingStatus = action.meta.arg.isFirstRequest
        ? FETCHING_STATUSES.LOADING
        : FETCHING_STATUSES.SUCCESS;
      state.messages.fetchingStatusLoadMore = action.meta.arg.isFirstRequest
        ? FETCHING_STATUSES.SUCCESS
        : FETCHING_STATUSES.LOADING;
    });
    builder.addCase(getTelegramDialogIdMessages.fulfilled, (state, action) => {
      if (action.payload) {
        state.messages.fetchingStatus = FETCHING_STATUSES.SUCCESS;
        state.messages.fetchingStatusLoadMore = FETCHING_STATUSES.SUCCESS;
        state.messages.results = action.payload.isFirstRequest
          ? action.payload.results
          : [...action.payload.results, ...state.messages.results];
        state.messages.next = action.payload.next;
        state.messages.previous = action.payload.previous;
        state.messages.count = action.payload.count;
      }
    });
    builder.addCase(getTelegramDialogIdMessages.rejected, (state) => {
      state.messages.fetchingStatus = FETCHING_STATUSES.ERROR;
    });
    builder.addCase(createTelegramDialog.pending, (state) => {
      state.newCreatedDialog.fetchingStatus = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(createTelegramDialog.fulfilled, (state, action) => {
      state.newCreatedDialog.fetchingStatus = FETCHING_STATUSES.SUCCESS;
      state.newCreatedDialog.result = action.payload;
    });
    builder.addCase(createTelegramDialog.rejected, (state) => {
      state.newCreatedDialog.fetchingStatus = FETCHING_STATUSES.ERROR;
    });
    builder.addCase(sendTelegramMessage.pending, (state) => {
      state.sendMessageFetchingStatus = FETCHING_STATUSES.LOADING;
    });
    builder.addCase(sendTelegramMessage.fulfilled, (state, action) => {
      state.sendMessageFetchingStatus = FETCHING_STATUSES.SUCCESS;
      state.messages.results = [...state.messages.results, action.payload];
    });
    builder.addCase(sendTelegramMessage.rejected, (state) => {
      state.sendMessageFetchingStatus = FETCHING_STATUSES.ERROR;
    });
  },
});

export const { resetDialogs, setOffsetDialogs, setOffsetMessages } = telegramDialogsSlice.actions;
export const telegramDialogs = telegramDialogsSlice.reducer;
