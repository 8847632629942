import { IntlShape } from 'react-intl';
import { flatten, get, isEmpty, isString, noop } from 'lodash';

import { isMailingAppConnected } from 'lib/messaging';
import { isManager } from 'lib/utils';

export const OPERATOR_BETWEEN = { OR: 'OR', AND: 'AND' };

const UPDATABILITY = {
  ALWAYS: 0,
  NEVER: 1,
  ON_DEMAND: 2,
};

export type Config = Map<string, AH$FilterConfig>;

const config: Config = new Map<string, AH$FilterConfig>([
  [
    'general',
    {
      defaultOpened: true,
      updatability: UPDATABILITY.NEVER,
      isRange: false,
      isExcludable: (id) =>
        [
          'viewsTransformer',
          'extViewed',
          'smartrecruiters',
          'huntflow',
          'huntflowInWork',
          'remote',
          'isFreelancer',
          'isFrequentJobChanger',
          'greenhouse',
          'greenhouseInWork',
          'workable',
          'workableInWork',
          'commentDays',
          'shown',
          'views',
          'folders',
          'contacted',
        ].includes(id),
      searchable: false,
      operatorBetween: OPERATOR_BETWEEN.AND,
      isShowedFilter: () => true,
      isHidden: () => false,
      valIdsForUrlFacetIds: [
        'views',
        'shown',
        'folders',
        'contacted',
        'extViewed',
        'excludeMultipleLocations',
        // MAG-9947 Скрытие фильтра hiddenStars
        // 'hiddenStars',
        'hasComment',
        'mltrDays',
        'mltr', // more likely to respond
        'smartrecruiters',
        'huntflow',
        'huntflowInWork',
        'greenhouse',
        'greenhouseInWork',
        'workable',
        'workableInWork',
        'remote',
        'isFreelancer',
        'isFrequentJobChanger',
        'commentDays',
      ],
      hasBetaBadge: () => false,
      getCustomFilters: (user: AH$User) =>
        ['viewsTransformer', ...(config as Map<string, any>).get('general').valIdsForUrlFacetIds]
          .filter(
            (id: string) =>
              (id !== 'mltrDays' ||
                (get(user, 'company.enableMltr') && get(user, 'company.mltrDaysFilter')) ||
                isManager(user.role)) &&
              (id !== 'mltr' ||
                (get(user, 'company.enableMltr') && !get(user, 'company.mltrDaysFilter')) ||
                isManager(user.role)) &&
              (id !== 'commentDays' || get(user, 'company.commentDaysFilter')) &&
              (id !== 'remote' || get(user, 'company.remoteFilter')) &&
              (id !== 'smartrecruiters' || !!get(user, 'socialAccounts.smartrecruitersImport')) &&
              (id !== 'viewsTransformer' || user.company.companyId !== 4) &&
              (!['views', 'shown', 'folders'].includes(id) || user.company.companyId === 4) &&
              (id !== 'contacted' ||
                (user.company.companyId === 4 && isMailingAppConnected(user))) &&
              (id !== 'extViewed' || get(user, 'company.linkedinFilter')) &&
              (!['hunflow', 'huntflowInWork'].includes(id) ||
                !!get(user, 'socialAccounts.huntflowImport')) &&
              (!['greenhouse', 'greenhouseInWork'].includes(id) ||
                !!get(user, 'socialAccounts.greenhouseImport')) &&
              (!['workable', 'workableInWork'].includes(id) ||
                !!get(user, 'socialAccounts.workable'))
          )
          .map((id: string) => ({ id, name: id, children: [] })),
      inactiveFilters: [],
      getCustomCheckedIds: (id: string) => {
        switch (id) {
          case 'viewsTransformer': {
            return ['views', 'shown', 'folders', 'contacted'];
          }
          default: {
            return undefined;
          }
        }
      },
    },
  ],
  [
    'age',
    {
      defaultOpened: true,
      updatability: UPDATABILITY.NEVER,
      isRange: true,
      isExcludable: () => false,
      searchable: false,
      operatorBetween: OPERATOR_BETWEEN.OR,
      isShowedFilter: () => true,
      isHidden: (user, gdpr: AH$GdprFilters) => !gdpr.age,
      valIdsForUrlFacetIds: [],
      _notSpecifiedId: 'showWithoutAge',
      getCustomFilters: noop,
      inactiveFilters: [],
      getCustomCheckedIds: () => undefined,
      hasBetaBadge: () => false,
    },
  ],
  [
    'experience',
    {
      defaultOpened: true,
      updatability: UPDATABILITY.NEVER,
      isRange: true,
      isExcludable: () => false,
      searchable: false,
      operatorBetween: OPERATOR_BETWEEN.OR,
      isShowedFilter: () => true,
      isHidden: () => false,
      valIdsForUrlFacetIds: [],
      _notSpecifiedId: 'showWithoutExperience',
      getCustomFilters: () => [
        ...(config as Map<string, any>).get('experience').valIdsForUrlFacetIds,
      ],
      inactiveFilters: [],
      getCustomCheckedIds: () => undefined,
      hasBetaBadge: () => false,
    },
  ],
  [
    'onLastPosition',
    {
      defaultOpened: true,
      updatability: UPDATABILITY.NEVER,
      isRange: true,
      isExcludable: () => false,
      searchable: false,
      operatorBetween: OPERATOR_BETWEEN.OR,
      isShowedFilter: () => true,
      isHidden: () => false,
      valIdsForUrlFacetIds: [],
      _notSpecifiedId: 'showWithoutLastExperience',
      getCustomFilters: noop,
      inactiveFilters: [],
      getCustomCheckedIds: () => undefined,
      hasBetaBadge: () => false,
    },
  ],
  [
    'graduation',
    {
      defaultOpened: true,
      updatability: UPDATABILITY.NEVER,
      isRange: true,
      isExcludable: () => false,
      searchable: false,
      operatorBetween: OPERATOR_BETWEEN.OR,
      isShowedFilter: () => true,
      isHidden: () => false,
      valIdsForUrlFacetIds: [],
      _notSpecifiedId: 'showWithoutGraduation',
      getCustomFilters: noop,
      inactiveFilters: [],
      getCustomCheckedIds: () => undefined,
      hasBetaBadge: () => false,
    },
  ],
  [
    'contacts',
    {
      defaultOpened: true,
      updatability: UPDATABILITY.ALWAYS,
      isRange: false,
      isExcludable: (id) => !['any', 'no'].includes(id),
      searchable: false,
      operatorBetween: OPERATOR_BETWEEN.OR,
      isShowedFilter: (id: string) => ['any', 'email', 'phone', 'messengers', 'no'].includes(id),
      isHidden: () => false,
      valIdsForUrlFacetIds: [],
      getCustomFilters: noop,
      inactiveFilters: [],
      getCustomCheckedIds: () => undefined,
      hasBetaBadge: () => false,
    },
  ],
  [
    'language',
    {
      defaultOpened: false,
      updatability: UPDATABILITY.ON_DEMAND,
      isRange: false,
      isExcludable: () => false,
      searchable: false,
      operatorBetween: OPERATOR_BETWEEN.AND,
      isShowedFilter: () => true,
      isHidden: () => false,
      valIdsForUrlFacetIds: [],
      getCustomFilters: noop,
      inactiveFilters: [],
      getCustomCheckedIds: () => undefined,
      hasBetaBadge: () => false,
    },
  ],
  [
    'site',
    {
      defaultOpened: false,
      updatability: UPDATABILITY.ON_DEMAND,
      isRange: false,
      isExcludable: (id) => !['any', 'no'].includes(id),
      searchable: false,
      operatorBetween: OPERATOR_BETWEEN.AND,
      isShowedFilter: () => true,
      isHidden: () => false,
      valIdsForUrlFacetIds: [],
      getCustomFilters: noop,
      inactiveFilters: [],
      getCustomCheckedIds: () => undefined,
      hasBetaBadge: () => false,
    },
  ],
  [
    'seniorityLevel',
    {
      defaultOpened: false,
      updatability: UPDATABILITY.ON_DEMAND,
      isRange: false,
      isExcludable: (id) => !['any', 'no'].includes(id),
      searchable: false,
      operatorBetween: OPERATOR_BETWEEN.OR,
      isShowedFilter: () => true,
      isHidden: () => false,
      valIdsForUrlFacetIds: [],
      getCustomFilters: noop,
      inactiveFilters: [],
      getCustomCheckedIds: () => undefined,
      hasBetaBadge: () => false,
    },
  ],
  [
    'companySize',
    {
      defaultOpened: false,
      updatability: UPDATABILITY.ON_DEMAND,
      isRange: false,
      isExcludable: (id) => !['any', 'curr', 'prev', 'size'].includes(id),
      searchable: false,
      operatorBetween: OPERATOR_BETWEEN.OR,
      isShowedFilter: () => true,
      isHidden: () => false,
      valIdsForUrlFacetIds: [],
      getCustomFilters: (_: AH$User, values) =>
        isEmpty(values)
          ? []
          : [
              /* {
                id: 'any',
                name: '',
                children: [
                  {
                    id: 'curr',
                    name: '',
                    children: [],
                  },
                  {
                    id: 'prev',
                    name: '',
                    children: [],
                  },
                ],
              }, */
              {
                id: 'size',
                name: '',
                children: values,
              },
            ],
      inactiveFilters: ['size'],
      getCustomCheckedIds: () => undefined,
      hasBetaBadge: () => false,
    },
  ],
  [
    'industry',
    {
      defaultOpened: false,
      updatability: UPDATABILITY.ON_DEMAND,
      isRange: false,
      isExcludable: (id) => !['any', 'no'].includes(id),
      searchable: true,
      operatorBetween: OPERATOR_BETWEEN.OR,
      isShowedFilter: () => true,
      isHidden: () => false,
      valIdsForUrlFacetIds: [],
      getCustomFilters: noop,
      inactiveFilters: [],
      getCustomCheckedIds: () => undefined,
      hasBetaBadge: () => false,
    },
  ],
  [
    'mainSkillHigh',
    {
      defaultOpened: false,
      updatability: UPDATABILITY.ON_DEMAND,
      isRange: false,
      isExcludable: (id) => !['any', 'no'].includes(id),
      searchable: false,
      operatorBetween: OPERATOR_BETWEEN.OR,
      isShowedFilter: () => true,
      isHidden: () => true,
      valIdsForUrlFacetIds: [],
      getCustomFilters: noop,
      inactiveFilters: [],
      getCustomCheckedIds: () => undefined,
      hasBetaBadge: () => false,
    },
  ],
  [
    'diversity',
    {
      defaultOpened: false,
      updatability: UPDATABILITY.ON_DEMAND,
      isRange: false,
      isExcludable: () => false,
      searchable: false,
      operatorBetween: OPERATOR_BETWEEN.OR,
      isShowedFilter: () => true,
      isHidden: (user, gdpr) => !gdpr.diversity,
      valIdsForUrlFacetIds: [],
      getCustomFilters: noop,
      inactiveFilters: [],
      getCustomCheckedIds: () => undefined,
      hasBetaBadge: () => false,
    },
  ],
  [
    'gender',
    {
      defaultOpened: false,
      updatability: UPDATABILITY.ON_DEMAND,
      isRange: false,
      isExcludable: () => false,
      searchable: false,
      operatorBetween: OPERATOR_BETWEEN.OR,
      isShowedFilter: () => true,
      isHidden: (user, gdpr) => !gdpr.gender,
      valIdsForUrlFacetIds: [],
      getCustomFilters: noop,
      inactiveFilters: [],
      getCustomCheckedIds: () => undefined,
      hasBetaBadge: () => false,
    },
  ],
  [
    'lastCompany',
    {
      defaultOpened: false,
      updatability: UPDATABILITY.ON_DEMAND,
      isRange: false,
      isExcludable: (id) => !['any', 'no'].includes(id),
      searchable: true,
      operatorBetween: OPERATOR_BETWEEN.OR,
      isShowedFilter: () => true,
      isHidden: () => false,
      valIdsForUrlFacetIds: [],
      getCustomFilters: noop,
      inactiveFilters: [],
      getCustomCheckedIds: () => undefined,
      hasBetaBadge: () => false,
    },
  ],
  [
    'education',
    {
      defaultOpened: false,
      updatability: UPDATABILITY.ON_DEMAND,
      isRange: false,
      isExcludable: (id) => !['any', 'no'].includes(id),
      searchable: true,
      operatorBetween: OPERATOR_BETWEEN.OR,
      isShowedFilter: () => true,
      isHidden: () => false,
      valIdsForUrlFacetIds: [],
      getCustomFilters: noop,
      inactiveFilters: [],
      getCustomCheckedIds: () => undefined,
      hasBetaBadge: () => false,
    },
  ],
  [
    'educationLevel',
    {
      defaultOpened: false,
      updatability: UPDATABILITY.ON_DEMAND,
      isRange: false,
      isExcludable: (id) => !['any', 'no'].includes(id),
      searchable: false,
      operatorBetween: OPERATOR_BETWEEN.OR,
      isShowedFilter: () => true,
      isHidden: () => false,
      valIdsForUrlFacetIds: [],
      getCustomFilters: noop,
      inactiveFilters: [],
      getCustomCheckedIds: () => undefined,
      hasBetaBadge: () => false,
    },
  ],
  [
    'location',
    {
      defaultOpened: false,
      updatability: UPDATABILITY.ON_DEMAND,
      isRange: false,
      isExcludable: (id) => !['any', 'no'].includes(id),
      searchable: false,
      operatorBetween: OPERATOR_BETWEEN.OR,
      isShowedFilter: () => true,
      isHidden: () => false,
      valIdsForUrlFacetIds: [],
      getCustomFilters: noop,
      inactiveFilters: [],
      getCustomCheckedIds: () => undefined,
      hasBetaBadge: () => false,
    },
  ],
  [
    'specialization',
    {
      defaultOpened: false,
      updatability: UPDATABILITY.ON_DEMAND,
      isRange: false,
      isExcludable: (id) => !['any', 'no'].includes(id),
      searchable: true,
      operatorBetween: OPERATOR_BETWEEN.OR,
      isShowedFilter: () => true,
      isHidden: () => true,
      valIdsForUrlFacetIds: [],
      getCustomFilters: noop,
      inactiveFilters: [],
      getCustomCheckedIds: () => undefined,
      hasBetaBadge: () => false,
    },
  ],
]);

const getFacetKeysBy = (prop: keyof AH$FilterConfig) =>
  new Set(
    Array.from(config)
      .map(([facetId, { [prop]: flag }]) => (flag ? facetId : null))
      .filter(isString)
  );

const getFacetKeysByUpdatability = (abilities: Array<number>) =>
  new Set(
    Array.from(config)
      .map(([facetId, { updatability }]) => (abilities.includes(updatability) ? facetId : null))
      .filter(isString)
  );

export const facetsOrder = Array.from(config).map(([id]) => id);
export const defaultOpenedFacets = getFacetKeysBy('defaultOpened');
export const constHoldedFacets = getFacetKeysByUpdatability([UPDATABILITY.NEVER]);
export const constUpdatedFacets = getFacetKeysByUpdatability([UPDATABILITY.ALWAYS]);
export const searchableFacets = getFacetKeysBy('searchable');
export const rangeFacets = getFacetKeysBy('isRange');
export const valIdsForUrlFacetIds = flatten(
  Array.from(config).map(([facetId, { valIdsForUrlFacetIds }]) =>
    valIdsForUrlFacetIds.map((valId) => [facetId, valId])
  )
);
export const isAndBetween = (facetId: string): boolean =>
  get(config.get(facetId), 'operatorBetween') === OPERATOR_BETWEEN.AND;

export const rangeFiltersValues = {
  age: {
    0: 0,
    1: 18,
    2: 20,
    3: 25,
    4: 30,
    5: 35,
    6: 40,
    7: 50,
    8: Infinity,
  },
  experience: {
    0: 0,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 7,
    7: 10,
    8: Infinity,
  },
  onLastPosition: {
    0: 0,
    1: 6,
    2: 12,
    3: 18,
    4: 24,
    5: 36,
    6: 48,
    7: 60,
    8: Infinity,
  },
};

export const rangeConf = (intl: IntlShape) => ({
  age: {
    marks: {
      0: '0',
      1: '18',
      2: '20',
      3: '25',
      4: '30',
      5: '35',
      6: '40',
      7: '50',
      8: '60+',
    },
    valuesMax: 60,
  },
  experience: {
    marks: {
      0: '0',
      1: '1',
      2: '2',
      3: '3',
      4: '4',
      5: '5',
      6: '7',
      7: '10',
      8: '15+',
    },
    valuesMax: 15,
  },
  onLastPosition: {
    marks: {
      0: '0',
      1: intl.formatNumber(0.5),
      2: '1',
      3: intl.formatNumber(1.5),
      4: '2',
      5: '3',
      6: '4',
      7: '5',
      8: '10+',
    },
    valuesMax: 120,
  },
  graduation: {
    marks: {},
    valuesMin: 1970,
    valuesMax: new Date().getFullYear() + 7,
  },
});

export const transformerFilters = ['viewsTransformer'];

export default config;
